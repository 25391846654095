import { useEffect, useState } from 'react';
import { NFT, StakingNFT } from '../pages/Mint';
import { ethers } from 'ethers';
import { contractABI } from '../utils/contractABI';
import { basicStakingContractAddress, contractAddress, dev, getBasicStakingNfts, getNFTs } from '../services';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../stores/store';
import If from './If';
import Portal from './Portal';
import { basicStakingContractABI } from '../utils/basicStakingContractABI';
import axios from 'axios';
import { setBonesPoint, setChainId, setPermissionAddresses, setWalletAddress } from '../stores/walletSlice';

type NftItemProps = {
  id: number;
  imageUrl: string;
  revealed: boolean;
  approved: boolean;
  setNftList: React.Dispatch<React.SetStateAction<NFT[]>>;
  setBasicStakingNftList: React.Dispatch<React.SetStateAction<StakingNFT[]>>;
  handleReveal: boolean;
};

const NftItem = ({ id, imageUrl, revealed, approved, setNftList, setBasicStakingNftList, handleReveal }: NftItemProps) => {
  const [handlerModal, setHandlerModal] = useState(false);
  const { chainId, walletAddress, permissionAddresses, bonesPoint } = useSelector((state: RootState) => state.wallet);
  const [pointerHandler, setPointerHandler] = useState(false);

  const [handlerWaitingApprove, setHandlerWaitingApprove] = useState(false);
  const [handlerWaitingStake, setHandlerWaitingStake] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const handlePointerEnter = () => {
    setPointerHandler(true);
  };
  const handlePointerLeave = () => {
    setPointerHandler(false);
  };
  const handleTouchStart = () => {
    setPointerHandler((prev) => (revealed === false ? !prev : false));
  };
  const init = () => {
    dispatch(setWalletAddress(''));
    dispatch(setPermissionAddresses([]));
    dispatch(setBonesPoint('0'));
    dispatch(setChainId(-1));
  };
  const handleClickReveal = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);
    try {
      const tx = await contract.changeRevealed(id).catch((err) => console.log(err.toString()));
      await tx
        .wait()
        .then(async () => {
          const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

          setNftList(nftList);
        })
        .catch((err: any) => console.log(err.toString()));
    } catch (error) {
      console.error(error);
      alert('Reveal failed.');
    }
  };

  const handleClickImage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setHandlerModal(true);
  };

  const handleClickApprove = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
      return;
    }

    await contract
      .approve(basicStakingContractAddress, id)
      .then(async (tx) => {
        setHandlerWaitingApprove(true);
        console.log('approve');

        return await tx.wait();
      })
      .then((receipt) => {
        setHandlerWaitingApprove(false);

        if (receipt.status === 1) {
          setNftList((prev) => prev.map((v) => (v.id === id ? { ...v, approved: true } : v)));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClickStaking = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    const accessToken = localStorage.getItem('accessToken') ?? '';
    const refreshToken = localStorage.getItem('refreshToken') ?? '';

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const basicStakingContract = new ethers.Contract(basicStakingContractAddress, basicStakingContractABI, signer);

    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
      return;
    }

    await axios
      .post(dev + '/api/donation/bones_amount', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
      .then(async () => {
        await basicStakingContract
          .stakeNFT(id)
          .then(async (tx) => {
            setHandlerWaitingStake(true);

            await axios.post(dev + '/api/staking/stake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase(), txHash: tx.hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).catch(async (err) => {
              if (err.response.data.message === 'Generate Token.') {
                const accessToken = err.response.data.accessToken;
                const refreshToken = err.response.data.refreshToken;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                await axios.post(dev + '/api/staking/stake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase(), txHash: tx.hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } });
              } else {
                if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                return;
              }
            });

            return tx.wait();
          })
          .then(async (receipt) => {
            setHandlerWaitingStake(false);
            if (receipt.status === 1) {
              const initNFTs = async () => {
                if (walletAddress !== '' && window.ethereum) {
                  const provider = new ethers.BrowserProvider(window.ethereum);
                  const signer = await provider.getSigner();

                  if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
                    return;
                  }

                  const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

                  setNftList(nftList);
                } else {
                  return;
                }
              };

              initNFTs();

              const initStakingNFTs = async () => {
                if (!window.ethereum || walletAddress === '') return;

                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();

                if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
                  return;
                }

                await getBasicStakingNfts(walletAddress).then((res) => {
                  if (res === undefined) return;
                  else {
                    setBasicStakingNftList(res);
                  }
                });
              };

              initStakingNFTs();
            } else {
              await axios.post(dev + '/api/staking/delete-recent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } });
            }
          })
          .catch(async (err) => {
            console.log(err);
          });
      })
      .catch(async (err) => {
        if (err.response.data.message === 'Generate Token.') {
          const accessToken = err.response.data.accessToken;
          const refreshToken = err.response.data.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          await basicStakingContract
            .stakeNFT(id)
            .then(async (tx) => {
              setHandlerWaitingStake(true);

              await axios.post(dev + '/api/staking/stake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase(), txHash: tx.hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } });

              return tx.wait();
            })
            .then(async (receipt) => {
              setHandlerWaitingStake(false);
              if (receipt.status === 1) {
                const initNFTs = async () => {
                  if (walletAddress !== '' && window.ethereum) {
                    const provider = new ethers.BrowserProvider(window.ethereum);
                    const signer = await provider.getSigner();

                    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
                      return;
                    }

                    const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

                    setNftList(nftList);
                  } else {
                    return;
                  }
                };

                initNFTs();

                const initStakingNFTs = async () => {
                  if (!window.ethereum || walletAddress === '') return;

                  const provider = new ethers.BrowserProvider(window.ethereum);
                  const signer = await provider.getSigner();

                  if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
                    return;
                  }

                  await getBasicStakingNfts(walletAddress).then((res) => {
                    if (res === undefined) return;
                    else {
                      console.log(res);
                      setBasicStakingNftList(res);
                    }
                  });
                };

                initStakingNFTs();
              } else {
                await axios.post(dev + '/api/staking/delete-recent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } });
              }
            })
            .catch((err) => console.log(err));
          return;
        } else {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
        }
      });
  };

  return (
    <>
      <div className='w-[27.5rem] h-[32.5rem] rounded-[0.5rem] bg-white flexCol mr-[2rem] mb-[2rem] p-[2rem] justify-start l:w-[23.5%] l:h-[auto] l:mr-[2%] l:mb-[2%] l:p-[2%] nth-child-4n-mr-0 p:w-[23.5%] p:h-[auto] p:mr-[2%] t:w-[23.5%] t:h-[auto] t:mr-[2%] cursor-pointer t:p-[2vw] t:mb-[2vw]' onPointerEnter={handlePointerEnter} onPointerLeave={handlePointerLeave} onTouchStart={handleTouchStart} onClick={handleClickImage}>
        <div className='w-[23.5rem] h-[23.5rem] rounded-[0.5rem] bg-[#ddd] mb-[2rem] l:mb-[1.8rem] p:mb-[1.4rem] t:mb-[2vw] bg-contain bg-center bg-no-repeat relative l:w-[100%] l:h-[0rem] l:pb-[100%] p:w-[100%] p:h-[0rem] p:pb-[100%] t:w-[100%] t:h-[0rem] t:pb-[100%]'>
          <img src={imageUrl} alt='' className='w-100% h-100% absolute border' />
          <div className={`w-[100%] h-[100%] absolute transition-all-300 backdrop-blur-sm ${pointerHandler === true && revealed === false ? 'flexRow' : 'hidden'}`}>
            <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[2vw] t:px-[3vw] t:py-[1vw]' onClick={handleClickReveal}>
              REVEAL
            </div>
          </div>
          <div className={`w-[100%] h-[100%] absolute transition-all-300 backdrop-blur-sm ${pointerHandler === true && revealed === true && approved === false && handlerWaitingApprove === false ? 'flexRow' : 'hidden'}`}>
            <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[2vw] t:px-[3vw] t:py-[1vw]' onClick={handleClickApprove}>
              APPROVE
            </div>
          </div>
          <div className={`w-[100%] h-[100%] absolute transition-all-300 backdrop-blur-sm ${pointerHandler === true && revealed === true && approved === true && handlerWaitingStake === false ? 'flexRow' : 'hidden'}`}>
            <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[2vw] t:px-[3vw] t:py-[1vw]' onClick={handleClickStaking}>
              STAKING
            </div>
          </div>

          <div className={`absolute left-[0px] top-[0px] w-[100%] h-[100%] ${revealed === true && approved === false && handlerWaitingApprove === true ? 'flexRow' : 'hidden'}`}>
            <div className='w-[50%] h-[50%] bg-contain-center animate-[rotate-record_1s_infinite_linear]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
          </div>
          <div className={`absolute left-[0px] top-[0px] w-[100%] h-[100%] ${revealed === true && approved === true && handlerWaitingStake === true ? 'flexRow' : 'hidden'}`}>
            <div className='w-[50%] h-[50%] bg-contain-center animate-[rotate-record_1s_infinite_linear]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
          </div>
        </div>
        <div className='w-[100%] h-[3rem] flexRow justify-between l:h-[2.5rem] p:h-[1.8rem] t:h-[2.5vw]'>
          <div className='font-roboto font-[600] text-[2.5rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw]'>No.</div>
          <div className='font-roboto font-[600] text-[2.5rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw]'>{id}</div>
        </div>
      </div>
      <If condition={handlerModal === true}>
        <Portal handleModal={handlerModal} setHandleModal={setHandlerModal}>
          <article className='px-[4rem] py-[4rem] w-[70rem] rounded-[0.5rem] overflow-hidden bg-[#FFFFFF] flexCol cursor-auto t:w-[70vw] t:px-[5vw] t:rounded-[0.5vw] relative l:w-[60rem] p:w-[50rem]'>
            <div className='bg-no-repeat w-[100%] pb-[100%] bg-center bg-cover border rounded-[0.5rem] t:rounded-[0.5vw] relative' style={{ backgroundImage: `url("${imageUrl}")` }}>
              <div className='absolute left-[0px] top-[0px] w-[100%] h-[100%] flexRow'>
                <If condition={revealed === false}>
                  <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[4vw] t:px-[6vw] t:py-[2vw]' onClick={handleClickReveal}>
                    REVEAL
                  </div>
                </If>
                <If condition={revealed === true && approved === false && handlerWaitingApprove === true}>
                  <div className='absolute left-[0px] top-[0px] w-[100%] h-[100%] flexRow'>
                    <div className='w-[50%] h-[50%] bg-contain-center animate-[rotate-record_1s_infinite_linear]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
                  </div>
                </If>
                <If condition={revealed === true && approved === false && handlerWaitingApprove === false}>
                  <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[4vw] t:px-[6vw] t:py-[2vw]' onClick={handleClickApprove}>
                    APPROVE
                  </div>
                </If>
                <If condition={revealed === true && approved === true && handlerWaitingStake === true}>
                  <div className='absolute left-[0px] top-[0px] w-[100%] h-[100%] flexRow'>
                    <div className='w-[50%] h-[50%] bg-contain-center animate-[rotate-record_1s_infinite_linear]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
                  </div>
                </If>
                <If condition={revealed === true && approved === true && handlerWaitingStake === false}>
                  <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[4vw] t:px-[6vw] t:py-[2vw]' onClick={handleClickStaking}>
                    STAKING
                  </div>
                </If>
              </div>
            </div>
          </article>
        </Portal>
      </If>
    </>
  );
};

export default NftItem;
