import { ReactTyped } from 'react-typed';
import DonationHeader from '../layouts/DonationHeader';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../stores/store';

import { ethers } from 'ethers';
import { dev, requestBonePoints, requestDonate, requestDonatedZenAmount } from '../services';
import { setBonesPoint, setChainId, setPermissionAddresses, setWalletAddress } from '../stores/walletSlice';
import moment from 'moment-timezone';
import axios from 'axios';

const Donation = () => {
  const websocketURL = process.env.REACT_APP_WEB_SOCKET ?? '';
  const toAddress = process.env.REACT_APP_TO_ADDRESS ?? '';

  const { chainId, walletAddress, permissionAddresses, bonesPoint } = useSelector((state: RootState) => state.wallet);

  const [leftDays, setLeftDays] = useState(0);
  const [leftHours, setLeftHours] = useState(0);
  const [leftMinutes, setLeftMinutes] = useState(0);
  const [leftSeconds, setLeftSeconds] = useState(0);

  const [hoursPassed, setHoursPassed] = useState(0);

  const [leftMinutesForIncreasingPrice, setLeftMinutesForIncreasingPrice] = useState(0);
  const [leftSecondsForIncreasingPrice, setLeftSecondsIncreasingPrice] = useState(0);

  const [leftDaysForDonating, setLeftDaysForDonating] = useState(0);
  const [leftHoursForDonating, setLeftHoursForDonating] = useState(0);
  const [leftMinutesForDonating, setLeftMinutesForDonating] = useState(0);
  const [leftSecondsForDonating, setLeftSecondsForDonating] = useState(0);

  const [startDonateHandler, setStartDonateHandler] = useState(false);
  const [endDonateHandler, setEndDonateHandler] = useState(false);

  const [zenAmount, setZenAmount] = useState('0');

  const [nowTime, setNowTime] = useState(0);
  const [standardTime, setStandardTime] = useState(0);

  const [totalDonatedZenAmount, setTotalDonatedZenAmount] = useState(0);

  const init = () => {
    dispatch(setWalletAddress(''));
    dispatch(setPermissionAddresses([]));
    dispatch(setBonesPoint('0'));
    dispatch(setChainId(-1));
    setZenAmount('0');
  };

  const calculateBonePoints = (zenAmount: number) => {
    if (zenAmount <= 0) return 0;
    if (zenAmount <= 10) return zenAmount * 500;
    if (zenAmount <= 20) return zenAmount * 510;
    if (zenAmount <= 30) return zenAmount * 520;
    if (zenAmount <= 40) return zenAmount * 530;
    if (zenAmount <= 50) return zenAmount * 540;
    if (zenAmount <= 60) return zenAmount * 550;
    if (zenAmount <= 70) return zenAmount * 560;
    if (zenAmount <= 80) return zenAmount * 570;
    if (zenAmount <= 90) return zenAmount * 580;
    if (zenAmount <= 100) return zenAmount * 590;
    if (zenAmount > 100) return zenAmount * 600;
  };

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = '#FFC82C';
    }
  }, []);

  useEffect(() => {
    const utcTimeZone = 'UTC';

    const calculateTimePassed = () => {
      const nowTime = moment.tz(utcTimeZone);
      const startTime = moment.tz('2024-08-20 10:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);

      const diffMilliseconds = nowTime.diff(startTime);
      const hoursPassed = Math.floor(diffMilliseconds / 3600000);
      const leftMinutesForIncreasingPrice = 59 - nowTime.minutes();
      const leftSecondsForIncreasingPrice = 59 - nowTime.seconds();

      setHoursPassed(hoursPassed);

      setLeftMinutesForIncreasingPrice(leftMinutesForIncreasingPrice);
      setLeftSecondsIncreasingPrice(leftSecondsForIncreasingPrice);
    };

    const calculateTimeLeft = () => {
      const nowTime = moment().tz('UTC');
      const startTime = moment.tz('2024-08-20 10:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);
      const endTime = moment.tz('2024-09-16 10:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);

      const diffMillisecondsForStarting = startTime.diff(nowTime);

      if (diffMillisecondsForStarting <= 0) {
        setStartDonateHandler(true);
      }

      const leftDaysForDonating = Math.floor(diffMillisecondsForStarting / (1000 * 60 * 60 * 24));
      const leftHoursForDonating = Math.floor((diffMillisecondsForStarting % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const leftMinutesForDonating = Math.floor((diffMillisecondsForStarting % (1000 * 60 * 60)) / (1000 * 60));
      const leftSecondsForDonating = Math.floor((diffMillisecondsForStarting % (1000 * 60)) / 1000);

      setLeftDaysForDonating(leftDaysForDonating);
      setLeftHoursForDonating(leftHoursForDonating);
      setLeftMinutesForDonating(leftMinutesForDonating);
      setLeftSecondsForDonating(leftSecondsForDonating);

      const diffMilliseconds = endTime.diff(nowTime);

      if (diffMilliseconds < 0) {
        setEndDonateHandler(true);
      }

      const leftDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
      const leftHours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const leftMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const leftSeconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

      setNowTime(nowTime.valueOf() - startTime.valueOf());
      setStandardTime(endTime.valueOf() - startTime.valueOf());

      setLeftDays(leftDays);
      setLeftHours(leftHours);
      setLeftMinutes(leftMinutes);
      setLeftSeconds(leftSeconds);
    };

    calculateTimePassed();
    calculateTimeLeft();

    const intervalId = setInterval(() => {
      calculateTimePassed();
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const dispatch: AppDispatch = useDispatch();

  const handleChangeZenAmount = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length) {
      if (/^[0-9\s.]*$/.test(event.target.value) && event.target.value.length < 6) {
        setZenAmount(event.target.value);
      }
    } else {
      setZenAmount(event.target.value);
    }
  };

  const handleBlurZenAmount = () => {
    setZenAmount((prev) => {
      const str = prev;
      if (str === '') return '0';
      return '' + parseInt(str.replace(/[^0-9]/g, '')) === 'NaN' ? '0' : '' + parseInt(str.replace(/[^0-9]/g, ''));
    });
  };

  const handleClickDonate = async () => {
    if (!window.ethereum) {
      alert('MetaMask is not connected.');
      return;
    }
    if (walletAddress === '' || localStorage.getItem('accessToken') === null || localStorage.getItem('refreshToken') === null) {
      alert('Please connect your wallet to the EON CHAIN.');
      return;
    }
    if (+zenAmount <= 0) {
      alert('Please check the ZEN amount you entered again.');
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();

    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
      alert('Please switch to the EON CHAIN.');
      return;
    } else {
      const valueInWei = ethers.parseUnits(zenAmount.toString(), 'ether');

      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (accessToken === null || refreshToken === null) return;

      await axios
        .post(dev + '/api/donation/bones_amount', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
        .then(async () => {
          await signer
            .sendTransaction({ to: toAddress, value: valueInWei, gasLimit: 21000, gasPrice: ethers.parseUnits('20', 'gwei') })
            .then(async (res) => {
              const { hash } = res;

              await axios
                .post(dev + '/api/donation/donation', { walletAddress: walletAddress.toLocaleLowerCase(), zenAmount, txHash: hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                .then((res) => {
                  const bonesPoint = res.data;

                  dispatch(setBonesPoint(bonesPoint));
                  setZenAmount('0');

                  alert('Thank you for donating!');
                })
                .catch(async (err) => {
                  switch (err.response.data.message) {
                    case 'Generate Token.':
                      const accessToken = err.response.data.accessToken;
                      const refreshToken = err.response.data.refreshToken;

                      localStorage.setItem('accessToken', accessToken);
                      localStorage.setItem('refreshToken', refreshToken);

                      await axios
                        .post(dev + '/api/donation/donation', { walletAddress: walletAddress.toLocaleLowerCase(), zenAmount, txHash: hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                        .then((res) => {
                          const bonesPoint = res.data;

                          dispatch(setBonesPoint(bonesPoint));
                          setZenAmount('0');

                          alert('Thank you for donating!');
                        })
                        .catch((err) => {
                          alert('Please contact administrator.' + err.toString());
                        });
                      break;
                    default:
                      alert('Please contact administrator.' + err.toString());
                      break;
                  }
                });
            })
            .catch((err) => {
              console.log(err.toString());
            });
        })
        .catch(async (err) => {
          switch (err.response.data.message) {
            case 'Generate Token.':
              const accessToken = err.response.data.accessToken;
              const refreshToken = err.response.data.refreshToken;

              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);

              await signer
                .sendTransaction({ to: toAddress, value: valueInWei, gasLimit: 21000, gasPrice: ethers.parseUnits('20', 'gwei') })
                .then(async (res) => {
                  const { hash } = res;

                  await axios
                    .post(dev + '/api/donation/donation', { walletAddress: walletAddress.toLocaleLowerCase(), zenAmount, txHash: hash }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                    .then((res) => {
                      const bonesPoint = res.data;

                      dispatch(setBonesPoint(bonesPoint));
                      setZenAmount('0');

                      alert('Thank you for donating!');
                    })
                    .catch(async (err) => {
                      alert('Please contact administrator.' + err.toString());
                    });
                })
                .catch((err) => {
                  console.log(err.toString());
                });
              break;
            default:
              if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
              else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
              break;
          }
        });
    }
  };

  const printStrTime = (time: number) => {
    return time < 10 ? '0' + time : time;
  };

  useEffect(() => {
    const ws = new WebSocket(websocketURL);

    ws.onopen = () => {
      console.log('WebSocket connection');
      try {
        ws.send('get_total_donation_zen');
      } catch (error) {
        console.error('WebSocket send error: ', error);
      }
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.totalZenAmount !== undefined) {
          setTotalDonatedZenAmount(data.totalZenAmount);
        } else {
          console.log('Unexpected data format');
        }
      } catch (error) {
        console.error('Error handling message: ', error);
      }
    };

    ws.onclose = () => console.log('WebSocket disconnection');

    return () => ws.close();
  }, []);

  const handleClickHorizen = () => {
    window.open('https://eon.horizen.io/app/start-here', '_blank');
  };
  return (
    <>
      <DonationHeader></DonationHeader>
      <section className='w-100% pt-[11.6rem] bg-[#FFC82C] flexRow p:pt-[8rem] t:pt-[15vw]'>
        <article className='max-w-[120rem] px-[2rem] w-100% flexCol t:px-[2vw]'>
          <h2 className='text-[14rem] l:text-[12rem] leading-[14rem] l:leading-[12rem] font-impact my-[3rem] l:my-[2.5rem] font-[900] text-red tracking-[0rem] z-100 d:[text-shadow:0px_4px_4px_#000000] l:[text-shadow:0px_3px_3px_#000000] d:[-webkit-text-stroke:10px_#000000] l:[-webkit-text-stroke:8px_#000000] l:pt-[3rem] p:text-[7rem] p:leading-[7rem] p:my-[2rem] p:[text-shadow:0px_2px_2px_#000000] p:[-webkit-text-stroke:5px_#000000] t:text-[15vw] t:leading-[15vw] t:mt-[4vw] t:mb-[0vw] t:[text-shadow:0px_0.7vw_0.7vw_#000000] t:[-webkit-text-stroke:0.7vw_#000000]'>GET YOUR</h2>
          <h2 className='text-[14rem] l:text-[12rem] leading-[14rem] l:leading-[10rem] font-impact font-[900] text-red tracking-[0rem] z-100 d:[text-shadow:0px_4px_4px_#000000] l:[text-shadow:0px_3px_3px_#000000] d:[-webkit-text-stroke:10px_#000000] l:[-webkit-text-stroke:8px_#000000] p:text-[7rem] p:leading-[7rem] p:[text-shadow:0px_2px_2px_#000000] p:[-webkit-text-stroke:5px_#000000] t:text-[15vw] t:leading-[15vw] t:[text-shadow:0px_0.7vw_0.7vw_#000000] t:[-webkit-text-stroke:0.7vw_#000000]'>BONES</h2>
          <div className='w-100% h-[55rem] relative p:h-[25rem] t:h-[40vw]'>
            <div className='absolute top-[16rem] left-[5rem] z-50 p:top-[4rem] t:top-[25%] t:left-[10%]'>
              <p className='text-[3rem] l:text-[2.7rem] font-roboto tracking-[0rem] leading-[4rem] l:leading-[3.5rem] font-[800] p:text-[2rem] p:leading-[2.5rem] t:text-[3vw] t:leading-[4vw]'>FIRST FANCY MEMECOIN</p>
              <p className='text-[3rem] l:text-[2.7rem] font-roboto tracking-[0rem] leading-[4rem] l:leading-[3.5rem] font-[800] p:text-[2rem] p:leading-[2.5rem] t:text-[3vw] t:leading-[4vw]'>ON HORIZEN</p>
              <p className='text-[3rem] l:text-[2.7rem] font-roboto tracking-[0rem] leading-[4rem] l:leading-[3.5rem] font-[800] p:text-[2rem] p:leading-[2.5rem] t:text-[3vw] t:leading-[4vw]'>DONATIONS ENDS ON</p>
              <p className='text-[3rem] l:text-[2.7rem] font-roboto tracking-[0rem] leading-[4rem] l:leading-[3.5rem] font-[800] text-red p:text-[2rem] p:leading-[2.5rem] t:text-[3vw] t:leading-[4vw]'>AUGUST 20, 10:00 UTC</p>
            </div>
            <div className='absolute w-[30rem] l:w-[23rem] h-[30rem] l:h-[23rem] d:left-[70%] l:right-[15%] top-[10rem] l:top-[16rem] p:w-[15rem] p:h-[15rem] p:right-[15%] p:top-[2rem] t:hidden'>
              <div className='w-[100%] h-[100%] relative bg-center bg-contain bg-no-repeat' style={{ backgroundImage: 'url("/images/d-1.png")' }}>
                <div className='absolute w-[2rem] h-[15rem] bg-contain bg-center bg-no-repeat top-[62%] left-[54%] p:w-[1rem] p:h-[7.5rem]'>
                  <div className='relative w-[100%] h-[100%] overflow-hidden'>
                    <div className='absolute w-[100%] h-[100%] bg-contain bg-top bg-no-repeat animate-[drop-w_1s_linear_infinite]' style={{ backgroundImage: 'url("/images/w-1.png")' }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='absolute w-[30rem] h-[30rem] d:left-[80%] top-[15rem] l:w-[23rem] l:h-[23rem] l:right-[5%] l:top-[20rem] p:w-[15rem] p:h-[15rem] p:right-[5%] p:top-[4rem] t:hidden'>
              <div className='w-[100%] h-[100%] relative bg-center bg-contain bg-no-repeat' style={{ backgroundImage: 'url("/images/d-2.png")' }}>
                <div className='absolute w-[2rem] h-[15rem] bg-contain bg-center bg-no-repeat top-[70.5%] left-[40%] p:w-[1rem] p:h-[7.5rem]'>
                  <div className='relative w-[100%] h-[100%] overflow-hidden'>
                    <div className='absolute w-[100%] h-[100%] bg-contain bg-top bg-no-repeat animate-[drop-w-2_1s_linear_infinite]' style={{ backgroundImage: 'url("/images/w-2.png")' }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-[70rem] h-[70rem] top-[-12rem] left-[55%] translate-x-[-50%] bg-center bg-no-repeat bg-contain absolute animate-[bounce-ball_1.5s_linear_infinite] z-40 l:w-[60rem] l:h-[60rem] l:top-[-5rem] l:left-[52%] p:w-[30rem] p:h-[30rem] p:top-[-5rem] t:w-[50vw] t:h-[50vw] t:top-[-20%] t:left-[70%] t:animate-[bounce-ball-m_1.5s_linear_infinite]' style={{ backgroundImage: 'url("images/bone.png")' }}></div>
            <div className='absolute top-[41rem] left-[55%] translate-x-[-50%] w-[38rem] h-[8rem] bg-[#444] rounded-[50%] animate-[bounce-shadow_1.5s_linear_infinite] l:w-[30rem] l:h-[7rem] l:left-[52%] p:w-[15rem] p:h-[3rem] p:top-[18rem] t:top-[75%] t:left-[70%] t:w-[25vw] t:h-[5vw]'></div>
          </div>
        </article>
      </section>
      <section className='w-100% flexRow bg-dark-gray pt-8rem pb-10rem p:pt-[4rem] p:pb-[5rem] t:pt-[7vw] t:pb-[7vw]'>
        <article className='max-w-[120rem] flexCol w-100%'>
          <div className='flexCol w-100%'>
            <h2 className='text-[10rem] font-oswald leading-[10rem] font-[800] text-red animate-[toggle-text-yellow_1s_infinite] l:text-[8rem] l:leading-[8rem] p:text-[4.5rem] p:leading-[4.5rem] t:text-[8vw] t:leading-[8vw]'>BY DONATING</h2>
            <h2 className='text-[10rem] font-oswald leading-[10rem] font-[800] text-red animate-[toggle-text-yellow_1s_infinite] pb-[15rem] l:text-[8rem] l:leading-[8rem] l:pb-[10rem] p:text-[4.5rem] p:leading-[4.5rem] p:pb-[4rem] t:text-[8vw] t:leading-[8vw] t:pb-[4vw]'>GET YOUR BONES IN TIME</h2>
          </div>
          <p className='text-[3rem] font-roboto leading-[3rem] font-[800] text-white pb-[1.5rem] l:text-[2.5rem] l:leading-[2.5rem] l:pb-[1.2rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.7rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw] t:font-[600]'>PeFUD is set to become the greatest and most successful meme coin project within</p>
          <p className='text-[3rem] font-roboto leading-[3rem] font-[800] text-white pb-[1.5rem] l:text-[2.5rem] l:leading-[2.5rem] l:pb-[1.2rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.7rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw] t:font-[600]'>the Horizen ecosystem. In this expansion, BONES will play a crucial role. However,</p>
          <div className='flexRow'>
            <p className='text-[3rem] font-roboto leading-[3rem] font-[800] pb-[1.5rem] text-[#DF3921] mr-[0.6rem] l:text-[2.5rem] l:leading-[2.5rem] l:pb-[1.2rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.7rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw] t:font-[600]'>it's important to note that BONES are not tokens but points, </p>
            <p className='text-[3rem] font-roboto leading-[3rem] font-[800] text-white pb-[1.5rem] l:text-[2.5rem] l:leading-[2.5rem] l:pb-[1.2rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.7rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw] t:font-[600]'>and</p>
          </div>
          <p className='text-[3rem] font-roboto leading-[3rem] font-[800] text-white l:text-[2.5rem] l:leading-[2.5rem] l:pb-[1.2rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.7rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw] t:font-[600]'>they will be used as a utility to support the growth of the PeFUD and EON ecosystem.</p>
        </article>
      </section>
      {/* first */}
      {/* <section className='w-100% flexRow pt-8rem pb-4rem'>
        <article className='max-w-[120rem] flexRow w-100%'>
          <div className='w-[40rem] h-[68rem] bg-coral'></div>
          <div className='w-[40rem] bg-dark-gray rounded-[0.5rem] flexCol pt-[3rem] px-[2rem] justify-start h-[68rem]'>
            <h3 className='text-white font-roboto font-[800] text-[5rem] leading-[5rem] mb-[1.5rem]'>DONATE</h3>
            <p className='text-white font-roboto font-[800] text-[2.5rem] leading-[2.5rem] mb-[1rem]'>DONATE END</p>
            <div className='w-100% flexRow mb-[2rem]'>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem] mr-[0.8rem]'>20d</div>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem] mr-[0.8rem]'>10h</div>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem] mr-[0.8rem]'>10m</div>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem]'>10s</div>
            </div>
            <p className='text-white font-roboto font-[800] text-[2.5rem] leading-[2.5rem] mb-[1rem]'>Until Next Price Increased</p>
            <div className='w-100% flexRow mb-[3rem]'>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem] mr-[0.8rem]'>18m</div>
              <div className='flexRow font-roboto font-[800] text-[2.3rem] leading-[2.3rem] w-[7rem] py-[0.9rem] bg-white rounded-[0.5rem]'>10s</div>
            </div>
            <div className='w-100% h-[1.8rem] bg-gray rounded-[1.8rem] flexRow justify-start mb-[2rem]'>
              <div className='bg-[#FFC82C] w-[1.8rem] h-[1.8rem] rounded-[1.8rem]'></div>
            </div>
            <p className='text-white text-[3rem] font-roboto font-[800] mb-[2.5rem]'>1 ZEN = 1000 BONES</p>
            <div className='flexRow w-100% mb-[2rem]'>
              <div className='w-[15rem] flexCol'>
                <div className='w-100% flexRow h-[3rem] mb-[1rem]'>
                  <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
                  <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>ZEN</div>
                </div>
                <input className='text-[3rem] font-[800] font-roboto flexRow w-100% h-[4rem] rounded-[0.5rem] text-center' type='number' />
              </div>
              <div className='w-[5rem]'>
                <div className='h-4rem'></div>
                <div className='h-4rem w-100% bg-coral'></div>
              </div>
              <div className='w-[15rem] flexCol'>
                <div className='w-100% flexRow h-[3rem] mb-[1rem]'>
                  <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
                  <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>BONES</div>
                </div>
                <input className='text-[3rem] font-[800] font-roboto flexRow w-100% h-[4rem] rounded-[0.5rem] text-center' type='number' />
              </div>
            </div>
            <p className='text-white text-[2rem] font-roboto font-[500]'>BONES are just points,</p>
            <p className='text-white text-[2rem] font-roboto font-[500] mb-[3rem]'>not coins or tokens.</p>
            <button className='px-[3rem] rounded-[0.5rem] pb-[1rem] pt-[1.1rem] font-roboto text-[2.7rem] leading-[3rem] text-white font-[800] bg-[#faa322] mb-[4rem]'>DONATE NOW</button>
          </div>
          <div className='w-[40rem] h-[68rem] bg-coral'></div>
        </article>
      </section> */}
      {/* first */}
      {/* second */}
      {/* <section className='w-100% flexCol pt-[8rem] pb-[8rem] bg-white'>
        <article className='max-w-[120rem] flexCol w-100% bg-dark-gray px-[3rem]'>
          <h2 className='text-white font-roboto text-[7rem] font-[800] mt-[3rem]'>DONATE</h2>
          <div className='w-100% flexRow mb-[2rem]'>
            <p className='text-white font-roboto text-[2.5rem] font-[800] mr-[1rem]'>DONATE END</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>20:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10</p>
          </div>
          <div className='w-100% h-[2.1rem] bg-gray rounded-[2.1rem] flexRow justify-start mb-[3rem]'>
            <div className='bg-[#FFC82C] w-[4.2rem] h-[2.1rem] rounded-[2.1rem]'></div>
          </div>
          <div className='w-100% flexRow items-end mb-[3rem]'>
            <p className='text-white font-roboto text-[3.5rem] leading-[3.5rem] font-[800] mr-2rem'>1 ZEN = 1000 BONES</p>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] mr-[0.6rem] pb-[0.3rem]'>Until next price increased:</p>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] pb-[0.2rem]'>18m 10s</p>
          </div>
          <div className='w-100% flexRow mb-[2rem]'>
            <div className='flexRow h-[3rem] mr-[2rem]'>
              <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>ZEN</div>
            </div>
            <input className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[2rem]' type='number' />
            <div className='flexRow h-[3rem] mr-[2rem]'>
              <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>BONES</div>
            </div>
            <input className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[2rem]' type='number' />
            <button className='px-[3rem] rounded-[0.5rem] pb-[1rem] pt-[1.1rem] font-roboto text-[2.7rem] leading-[3rem] text-white font-[800] bg-[#faa322]'>DONATE NOW</button>
          </div>
          <p className='text-white font-roboto text-[2rem] font-[800] mb-[2.5rem] mb-[5rem]'>BONES are just points, not coins or tokens.</p>
        </article>
        <article className='mt-[2rem] max-w-[120rem] flexCol w-100% bg-white border-t border-l'>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800] bg-red text-white'>DONATE ZEN</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800] bg-red text-white'>ZEN: BONES RATIO</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800] bg-red text-white'>DONATE ZEN</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800] bg-red text-white'>ZEN: BONES RATIO</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1 ~ 10</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1000</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>11 ~ 20</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1010</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>21 ~ 30</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1020</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>31 ~ 40</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1030</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>41 ~ 50</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1040</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>51 ~ 60</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1050</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>61 ~ 70</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1060</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>71 ~ 80</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1070</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>81 ~ 90</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1080</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>91 ~ 100</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1090</div>
          </div>
          <div className='w-100% h-[3.5rem] flexRow'>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>101 ~</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'>1:1100</div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'></div>
            <div className='w-[25%] h-[100%] border-r border-b flexRow text-[2.5rem] font-roboto font-[800]'></div>
          </div>
        </article>
      </section> */}
      {/* second */}
      {/* third */}
      {/* <section className='w-100% flexCol pt-[8rem] pb-[8rem] bg-white'>
        <article className='max-w-[120rem] flexCol w-100% bg-dark-gray px-[3rem]'>
          <h2 className='text-white font-roboto text-[7rem] font-[800] mt-[3rem]'>DONATE</h2>
          <div className='w-100% flexRow mb-[2rem]'>
            <p className='text-white font-roboto text-[2.5rem] font-[800] mr-[1rem]'>DONATE END</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>20:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10:</p>
            <p className='text-white font-roboto text-[2.5rem] font-[800]'>10</p>
          </div>
          <div className='w-100% h-[2.1rem] bg-gray rounded-[2.1rem] flexRow justify-start mb-[3rem]'>
            <div className='bg-[#FFC82C] w-[4.2rem] h-[2.1rem] rounded-[2.1rem]'></div>
          </div>
          <div className='w-100% flexRow items-end mb-[3rem]'>
            <p className='text-white font-roboto text-[3.5rem] leading-[3.5rem] font-[800] mr-2rem'>1 ZEN = 1000 BONES</p>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] mr-[0.6rem] pb-[0.3rem]'>Until next price increased:</p>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] pb-[0.2rem]'>18m 10s</p>
          </div>
          <div className='w-100% flexRow mb-[2rem]'>
            <div className='flexRow h-[3rem] mr-[2rem]'>
              <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>ZEN</div>
            </div>
            <input className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[2rem]' type='number' />
            <div className='flexRow h-[3rem] mr-[2rem]'>
              <div className='w-[3rem] h-[3rem] bg-coral bg-center bg-contain bg-no-repeat mr-[0.5rem]'></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto'>BONES</div>
            </div>
            <input className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[2rem]' type='number' />
            <button className='px-[3rem] rounded-[0.5rem] pb-[1rem] pt-[1.1rem] font-roboto text-[2.7rem] leading-[3rem] text-white font-[800] bg-[#faa322]'>DONATE NOW</button>
          </div>
          <p className='text-white font-roboto text-[2rem] font-[800] mb-[2.5rem] mb-[5rem]'>BONES are just points, not coins or tokens.</p>
        </article>
        <article className='mt-[2rem] max-w-[120rem] flexCol w-100% bg-white border-t border-l'>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b flexRow text-[2rem] leading-[2rem] font-roboto font-[800] bg-red text-white'>DONATE ZEN</div>
            <div className='w-[50%] h-[100%] border-r border-b flexRow text-[2rem] leading-[2rem] font-roboto font-[800] bg-red text-white'>ZEN: BONES RATIO</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1 ~ 10</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1000</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>11 ~ 20</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1010</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>21 ~ 30</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1020</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>31 ~ 40</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1030</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>41 ~ 50</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1040</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>51 ~ 60</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1050</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>61 ~ 70</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1060</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>71 ~ 80</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1070</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>81 ~ 90</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1080</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>91 ~ 100</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1090</div>
          </div>
          <div className='w-100% h-[3rem] flexRow'>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>101 ~</div>
            <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red'>1:1100</div>
          </div>
        </article>
      </section> */}
      {/* third */}
      {/* fourth */}
      <section className='w-100% flexCol pt-[8rem] bg-[#FFC82C] t:pt-[8vw]'>
        <article className='max-w-[120rem] flexCol w-100% bg-dark-gray px-[3rem] rounded-[0.5rem] l:w-[82rem] p:w-[58rem] t:w-[80vw] t:px-[4vw] rounded-[0.5vw]'>
          <h2 className='text-white font-roboto text-[7rem] font-[800] mt-[3rem] l:text-[6rem] p:text-[4rem] t:text-[6vw] t:mt-[7vw]'>DONATE</h2>
          {/* {startDonateHandler === false && endDonateHandler === false ? (
            <div className='w-100% flexRow mb-[2rem] p:mb-[1.5rem] t:mb-[2vw]'>
              <p className='text-white font-roboto text-[2.5rem] font-[800] mr-[1rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:mr-[1.5vw]'>DONATION STARTS IN:</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftDaysForDonating)}d</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftHoursForDonating)}h</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftMinutesForDonating)}m</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw]'>{printStrTime(leftSecondsForDonating)}s</p>
            </div>
          ) : startDonateHandler === true && endDonateHandler === false ? (
            <div className='w-100% flexRow mb-[2rem] p:mb-[1.5rem] t:mb-[2vw]'>
              <p className='text-white font-roboto text-[2.5rem] font-[800] mr-[1rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:mr-[1.5vw]'>DONATION ENDS IN:</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftDays)}d</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftHours)}h</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>{printStrTime(leftMinutes)}m</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw]'>{printStrTime(leftSeconds)}s</p>
            </div>
          ) : (
            <div className='w-100% flexRow mb-[2rem] p:mb-[1.5rem] t:mb-[2vw]'>
              <p className='text-white font-roboto text-[2.5rem] font-[800] mr-[1rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:mr-[1.5vw]'>DONATION ENDS IN:</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>00d</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>00h</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] pr-[0.6rem]'>00m</p>
              <p className='text-white font-roboto text-[2.5rem] font-[800] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw]'>00s</p>
            </div>
          )} */}
          <p className='text-white font-roboto text-[3.5rem] leading-[3.5rem] font-[800] mr-2rem l:text-[3.2rem] p:text-[2rem] t:text-[2.5vw] t:leading-[2.5vw] t:mr-[1vw]'>1 ZEN = 500 BONES</p>
          <div className='w-[80%] h-[2rem] bg-gray rounded-[0.5rem] flexRow justify-start mt-[2rem] mb-[2rem] p:h-[1.5rem] p:w-[80%] t:h-[1.5vw] t:w-[80%] t:mt-[4vw] t:rounded-[0.4vw] t:mb-[4vw]'>
            <div className='bg-[#FFC82C] h-[2rem] rounded-[0.5rem] p:h-[1.5rem] t:h-[3vw] t:rounded-[0.4vw]' style={{ width: `100%` }}></div>
          </div>
          <div className={`text-white font-roboto font-bold font-[800] text-[2.5rem] mb-[3rem] l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:mb-[4vw] ${totalDonatedZenAmount >= 5000 ? 'flex' : 'hidden'}`}>TOTAL DONATED ZEN AMOUNT: {parseFloat((+totalDonatedZenAmount).toFixed(2))} ZEN</div>
          {/* <div className='w-100% flexRow items-end mb-[3rem] p:mb-[0.3rem] t:mb-[4vw]'>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] mr-[0.6rem] pb-[0.3rem] l:text-[1.6rem] p:text-[1.4rem] p:pb-[0.5rem] t:text-[2.5vw] t:leading-[2.5vw] t:mr-[1vw] t:pb-[0]'>Until the next ratio adjustment:</p>
            <p className='text-white font-roboto text-[1.8rem] leading-[1.8rem] font-[400] pb-[0.2rem] l:text-[1.6rem] p:text-[1.4rem] p:pb-[0.5rem] t:text-[2.5vw] t:leading-[2.5vw] t:mr-[1vw] t:pb-[0]'>{startDonateHandler === true && endDonateHandler === false ? `${printStrTime(leftMinutesForIncreasingPrice)}m ${printStrTime(leftSecondsForIncreasingPrice)}s` : `00m 00s`}</p>
          </div> */}
          <div className='w-100% flexRow mb-[2rem] p:mb-[0rem] t:mb-[4vw]'>
            <div className='flexRow h-[4rem] mr-[2rem] p:mr-[1rem] t:h-[5vw] t:mr-[1.5vw]'>
              <div className='w-[3rem] h-[3rem] bg-center bg-contain bg-no-repeat mr-[1.3rem] p:w-[2.5rem] p:h-[2.5rem] t:w-[4vw] t:h-[4vw] t:mr-[1vw]' style={{ backgroundImage: 'url("/images/zen-logo.png")' }}></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:leading-[2.5vw]'>ZEN</div>
            </div>
            <input className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[1rem] l:w-[18rem] l:h-[3.5rem] l:text-[2.5rem] p:w-[13rem] p:h-[2.5rem] p:text-[1.8rem] p:pt-[0.1rem] p:rounded-[0.2rem] p:font-[600] t:text-[2.5vw] t:w-[17vw] t:h-[4vw] t:rounded-[0.5vw] t:mr-[1vw]' type='text' value={zenAmount} onChange={handleChangeZenAmount} onBlur={handleBlurZenAmount} />
            <div className='flexRow h-[4rem] mr-[2rem] p:mr-[1rem] t:h-[5vw] t:mr-[1.5vw]'>
              <div className='w-[5rem] h-[5rem] bg-center bg-contain bg-no-repeat p:w-[4rem] p:h-[4rem] t:w-[6.5vw] t:h-[6.5vw]' style={{ backgroundImage: 'url("/images/bone.png")' }}></div>
              <div className='text-[2.5rem] leading-[2.5rem] text-white font-[800] font-roboto l:text-[2.3rem] p:text-[1.8rem] t:text-[2.5vw] t:leading-[2.5vw]'>BONES</div>
            </div>
            <div className='text-[3rem] font-[800] font-roboto flexRow w-[20rem] h-[4rem] rounded-[0.5rem] text-center mr-[2rem] l:w-[18rem] l:h-[3.5rem] l:text-[2.5rem] p:w-[13rem] p:h-[2.5rem] p:text-[1.8rem] p:pt-[0.1rem] p:rounded-[0.2rem] p:font-[600] t:text-[2.5vw] t:w-[17vw] t:h-[4vw] t:rounded-[0.5vw] t:mr-[1vw] bg-white'>{calculateBonePoints(+zenAmount)}</div>
            <button className='px-[3rem] rounded-[0.5rem] pb-[1rem] pt-[1.1rem] font-roboto text-[2.7rem] leading-[3rem] text-white font-[800] bg-[#faa322] l:text-[2.5rem] l:hidden p:hidden t:hidden' onClick={handleClickDonate}>
              DONATE NOW
            </button>
          </div>
          <button className='px-[3rem] rounded-[0.2rem] pb-[0.8rem] pt-[0.9rem] font-roboto text-[2.2rem] leading-[3rem] text-white font-[800] bg-[#faa322] l:text-[2.5rem] hidden l:flex l:mt-[2rem] l:mb-[3rem] p:flexRow p:mb-[2rem] mt-[5rem] t:flex t:px-[6vw] t:rounded-[0.5vw] t:pb-[1vw] t:pt-[1.1vw] t:text-[4vw] t:leading-[4vw] t:mt-[2vw] t:mb-[8vw]' onClick={handleClickDonate}>
            DONATE NOW
          </button>
          <p className='text-white font-roboto text-[2rem] font-[800] l:text-[1.5rem] l:font-[400] p:text-[1.4rem] p:font-[400] p:flexRow p:leading-[1.7rem] t:text-[1.6vw] t:leading-[1.6vw] t:font-[400] t:mb-[1vw]'>BONES are not coins or tokens, but points,</p>
          <p className='text-white font-roboto text-[2rem] font-[800] mb-[2.5rem] mb-[5rem] l:text-[1.5rem] l:font-[400] p:text-[1.4rem] p:font-[400] p:flexRow p:leading-[1.7rem] t:text-[1.6vw] t:leading-[1.6vw] t:font-[400] t:mb-[6vw]'>and the act of donating does not guarantee any returns or profits.</p>
        </article>
      </section>
      <section className='w-100% flexRow bg-[#FFC82C] pt-[4rem] pb-[9rem] t:pt-[4vw] t:pb-[8vw]'>
        <article className='max-w-[120rem] flexRow w-100% l:mt-[5rem]'>
          <div className='w-[30%] h-[36.1rem] bg-contain bg-bottom bg-no-repeat pr-[3rem] l:w-[25%] l:h-[33.6rem] p:hidden t:hidden'>
            <div className='w-[100%] h-[100%] bg-contain bg-bottom bg-no-repeat' style={{ backgroundImage: 'url("/images/donation_pefud_01.png")' }}></div>
          </div>
          <div className='w-[40%] flexCol bg-white border-t border-l l:w-[50%] p:w-[58rem] t:w-[80vw]'>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] bg-red text-white l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>ZEN Donation Amount</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] bg-red text-white l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>ZEN To BONES Ratio</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1 ~ 10</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 500</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>11 ~ 20</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 510</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>21 ~ 30</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 520</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>31 ~ 40</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 530</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>41 ~ 50</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 540</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>51 ~ 60</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 550</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>61 ~ 70</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 560</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>71 ~ 80</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 570</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>81 ~ 90</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 580</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>91 ~ 100</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 590</div>
            </div>
            <div className='w-100% h-[3rem] flexRow l:h-[2.8rem] p:h-[2.7rem] t:h-[4.5vw]'>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>101 ~</div>
              <div className='w-[50%] h-[100%] border-r border-b border-black flexRow text-[2rem] leading-[2rem] font-roboto font-[800] text-red l:text-[1.8rem] p:text-[1.7rem] t:text-[3vw] t:leading-[3vw]'>1: 600</div>
            </div>
          </div>
          <div className='w-[30%] h-[36.1rem] bg-contain bg-bottom bg-no-repeat pl-[3rem] l:w-[25%] l:h-[33.6rem] p:hidden t:hidden'>
            <div className='w-[100%] h-[100%] bg-contain bg-bottom bg-no-repeat' style={{ backgroundImage: 'url("/images/donation_pefud_02.png")' }}></div>
          </div>
        </article>
      </section>
      {/* fourth */}
      {/* <section className='w-100% flexRow bg-dark-gray'>
        <article className='max-w-[120rem] flexCol w-100%'>
          <h3 className='text-[10rem] text-red font-oswald font-[800] [-webkit-text-stroke:2px_#ffffff] tracking-[-0.3rem] pt-10rem pb-11rem'>HOW TO DONATE?</h3>
        </article>
      </section> */}
      <section className='w-100% flexRow bg-dark-gray t:h-[35vw]'>
        <article className='max-w-[120rem] flexCol w-100% h-[30rem] pb-[6.5rem] l:hidden p:hidden t:hidden'>
          <ReactTyped strings={['HOW TO DONATE?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '10rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '2px #fff', letterSpacing: '-0.3rem', height: '10rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-100% h-[25rem] pb-[6rem] l:flexCol'>
          <ReactTyped strings={['HOW TO DONATE?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '8rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '2px #fff', letterSpacing: '-0.3rem', height: '8rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-100% h-[20rem] pb-[3.5rem] p:flexCol'>
          <ReactTyped strings={['HOW TO DONATE?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '5rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '1px #fff', letterSpacing: '-0.3rem', height: '5rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-100% t:flexCol t:pb-[7vw]'>
          <ReactTyped strings={['HOW TO DONATE?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '9vw', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '1px #fff', letterSpacing: '-0.3rem', paddingTop: '0vw', paddingBottom: '0vw', height: '9vw' }} />
        </article>
      </section>

      {/* first */}
      {/* <section className='w-100% flexCol pt-4rem'>
        <article className='max-w-[120rem] w-100% flexRow'>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[23rem] bg-[#FAA323] rounded-[0.5rem] mr-2rem'>
            <p className='font-roboto text-white font-[800] text-[2rem] mb-[2rem] leading-[2rem]'>PREPARE A WALLET</p>
            <p className='font-roboto text-[1.3rem] leading-[1.8rem]'>The potential BONES holder should have a EON chain wallet to operate with NFT and cryptocurrencies. Metamask works perfectly fine.</p>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[23rem] bg-[#FAA323] rounded-[0.5rem] mr-2rem'>
            <p className='font-roboto text-white font-[800] text-[2rem] mb-[2rem] leading-[2rem]'>BUY ZEN</p>
            <p className='font-roboto text-[1.3rem] leading-[1.8rem]'>To participate you need to have $ZEN on your wallet. You can buy ZEN on CEX like Binance... etc and send it to your wallet.</p>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[23rem] bg-[#FAA323] rounded-[0.5rem] mr-2rem'>
            <p className='font-roboto text-white font-[800] text-[2rem] mb-[2rem] leading-[2rem]'>GET BONES</p>
            <p className='font-roboto text-[1.3rem] leading-[1.8rem]'>After that, Enter 1 ZEN or more in the donate window above and click the donate button to donate. Depending on the amount of ZEN you donate, you can get BONES and if you donate a lot of ZEN at once, you can get additional BONES.</p>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[23rem] bg-[#FAA323] rounded-[0.5rem]'>
            <p className='font-roboto text-white font-[800] text-[2rem] mb-[2rem] leading-[2rem]'>GET NFT & ENJOY GAME</p>
            <p className='font-roboto text-[1.3rem] leading-[1.8rem]'>You can get NFTs with BONES, which are points earned through donations. Only people with NFTs can play games that increase the number of BONES.</p>
            <p className='font-roboto text-[1.3rem] leading-[1.8rem] mt-[1rem]'>And the most important thing is that the amount of $PeFUD paid out is determined by the amount of BONES held.</p>
          </div>
        </article>
        <article className='max-w-[120rem] flexCol w-100%'>
          <p className='w-100% font-roboto text-[1.8rem] font-[800] flexRow mt-[2rem]'>Any transactions with donations will be deemed lost and nonrefundable.</p>
          <h3 className='font-roboto w-100% text-[5rem] font-[800] flexRow mt-[5rem] mb-[2rem]'>DISTRIBUTION</h3>
          <p className='w-100% text-[1.5rem] flexRow font-roboto font-[700]'>BONES distribution begins 30 minutes after the announcement, and donors who send Zen first will have higher priority in the distribution process.</p>
          <p className='w-100% text-[1.5rem] flexRow font-roboto font-[700] pb-[4rem]'>Deployment will be public or private and may take several hours depending on server speed.</p>
        </article>f
      </section> */}
      {/* first */}
      {/* second */}
      <section className='w-100% flexCol pt-4rem bg-[#FFC82C] l:pt-[3.5rem] p:pt-[2rem] t:pt-[8vw]'>
        <article className='max-w-[120rem] w-100% flexRow p:flex-wrap t:flexCol'>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[25rem] bg-black rounded-[0.5rem] mr-2rem l:px-[1.6rem] l:py-[1.6rem] l:w-[23rem] l:h-[23.6rem] l:rounded-[0.4rem] l:mr-[1.6rem] p:mb-[2rem] p:w-[35rem] p:h-[21rem] p:rounded-[] t:mr-[0rem] t:px-[4vw] t:py-[4vw] t:w-[60vw] t:h-[40vw] t:rounded-[0.5vw] t:mb-[8vw]'>
            <div className='font-roboto text-red font-[800] text-[2rem] mb-[1.6rem] leading-[2rem] l:text-[1.7rem] l:mb-[1.7rem] l:leading-[1.7rem] p:text-[2rem] p:leading-[2rem] p:mb-[1.6rem] t:text-[4vw] t:w-[100%] t:flexRow t:mb-[2.8vw] t:leading-[4vw]'>PREPARE A WALLET</div>
            <div className='font-roboto text-[1.3rem] leading-[1.8rem] text-white font-[400] l:text-[1.2rem] l:leading-[1.5rem] t:text-[2vw] t:leading-[3vw]'>
              A wallet that can connect to the EON network is required to make a donation. For detailed instructions on how to install a wallet and connect it to the EON network, please visit the official EON website linked below.
              <div className='pt-[3rem] font-roboto text-[1.6rem] leading-[1.8rem] text-white font-[600] w-100% flexRow text-[orange] cursor-pointer l:pt-[2.7rem] l:text-[1.3rem] l:leading-[1.5rem] p:pt-[1.5rem] t:text-[3vw] t:leading-[3vw] t:pt-[5.5vw]' onClick={handleClickHorizen}>
                CLICK
              </div>
            </div>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[25rem] bg-black rounded-[0.5rem] mr-2rem l:px-[1.6rem] l:py-[1.6rem] l:w-[23rem] l:h-[23.6rem] l:rounded-[0.4rem] l:mr-[1.6rem] p:mr-[0rem] p:mb-[2rem] p:w-[35rem] p:h-[21rem] p:rounded-[] t:mr-[0rem] t:mr-[0rem] t:px-[4vw] t:py-[4vw] t:w-[60vw] t:h-[40vw] t:rounded-[0.5vw] t:mb-[8vw]'>
            <div className='font-roboto text-red font-[800] text-[2rem] mb-[1.6rem] leading-[2rem] l:text-[1.7rem] l:mb-[1.7rem] l:leading-[1.7rem] p:text-[2rem] p:leading-[2rem] p:mb-[1.6rem] t:text-[4vw] t:w-[100%] t:flexRow t:mb-[2.8vw] t:leading-[4vw]'>CONNECT WALLET</div>
            <div className='font-roboto text-[1.3rem] leading-[1.8rem] text-white font-[400] l:text-[1.2rem] l:leading-[1.5rem] t:text-[2vw] t:leading-[3vw]'>If you have sufficient $ZEN in your wallet for the donation, click on "Connect Wallet" at the top right of the page to connect your MetaMask. We do not require any risky contract signatures that could lead to hacking or asset theft in MetaMask. This is simply a wallet connection process. Please trust the team.</div>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[25rem] bg-black rounded-[0.5rem] mr-2rem l:px-[1.6rem] l:py-[1.6rem] l:w-[23rem] l:h-[23.6rem] l:rounded-[0.4rem] l:mr-[1.6rem] p:w-[35rem] p:h-[21rem] p:rounded-[] t:mr-[0rem] t:mr-[0rem] t:px-[4vw] t:py-[4vw] t:w-[60vw] t:h-[40vw] t:rounded-[0.5vw] t:mb-[8vw]'>
            <div className='font-roboto text-red font-[800] text-[2rem] mb-[1.6rem] leading-[2rem] l:text-[1.7rem] l:mb-[1.7rem] l:leading-[1.7rem] p:text-[2rem] p:leading-[2rem] p:mb-[1.6rem] t:text-[4vw] t:w-[100%] t:flexRow t:mb-[2.8vw] t:leading-[4vw]'>DONATE</div>
            <div className='font-roboto text-[1.3rem] leading-[1.8rem] text-white font-[400] l:text-[1.2rem] l:leading-[1.5rem] t:text-[2vw] t:leading-[3vw]'>The minimum donation amount is 1 $ZEN. The ratio of ZEN to BONES, which is our token of appreciation for your donation, will gradually decrease over time. Therefore, the sooner you donate, the more BONES you will receive, and the more you donate, the better the BONES ratio will be. Be sure to check the current ratio before clicking the "Donate Now" button!</div>
          </div>
          <div className='px-[2rem] py-[2rem] w-[28.5rem] h-[25rem] bg-black rounded-[0.5rem] l:px-[1.6rem] l:py-[1.6rem] l:w-[23rem] l:h-[23.6rem] l:rounded-[0.4rem] p:w-[35rem] p:h-[21rem] p:rounded-[] t:mr-[0rem] t:px-[4vw] t:py-[4vw] t:w-[60vw] t:h-[40vw] t:rounded-[0.5vw] t:mb-[4vw]'>
            <div className='font-roboto text-red font-[800] text-[2rem] mb-[1.6rem] leading-[2rem] l:text-[1.7rem] l:mb-[1.7rem] l:leading-[1.7rem] p:text-[2rem] p:leading-[2rem] p:mb-[1.6rem] t:text-[4vw] t:w-[100%] t:flexRow t:mb-[2.8vw] t:leading-[4vw]'>CHECK YOUR BONES</div>
            <div className='font-roboto text-[1.3rem] leading-[1.8rem] text-white font-[400] l:text-[1.2rem] l:leading-[1.5rem] t:text-[2vw] t:leading-[3vw]'>If your donation has been successfully completed, you will be able to see the amount of BONES received at the top right corner of the page shortly. Stay tuned for various events and utilities where you can make use of your BONES!</div>
          </div>
        </article>
        <article className='max-w-[120rem] flexCol w-100%'>
          <div className='w-100% font-roboto text-[1.8rem] font-[600] flexRow mt-[2rem] p:text-[1.5rem] t:text-[2vw] t:mt-[4vw]'>Any transactions involving donations are nonrefundable and cannot be recovered.</div>
          <h3 className='font-roboto w-100% text-[5rem] font-[800] flexRow mt-[5rem] mb-[2rem] p:text-[4rem] p:mt-[2rem] p:mb-[2rem] t:text-[7vw] t:mt-[10vw] t:mb-[]'>DISCLAMIMER</h3>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>BONES will be quickly visible after your donation, and the donation process may close suddenly without prior notice.</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>The total amount of BONES and their distribution will be handled privately.</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>We have no malicious intent or plans for asset theft in any transactions made to experience PeFUD.</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>All transactions are transparent, and our smart contract code is audited for security.</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>Additionally, we assume that donors have thoroughly reviewed all the information provided on the page</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] t:text-[1.65vw] t:leading-[2.4vw]'>before proceeding with their donation. However, please note that we cannot guarantee or</div>
          <div className='w-100% text-[1.5rem] flexRow font-roboto font-[700] leading-[1.9rem] p:text-[1.2rem] p:leading-[1.5rem] pb-[10rem] p:pb-[8rem] t:text-[1.65vw] t:leading-[2.4vw] t:pb-[12vw]'>be held responsible for any issues arising from actions taken outside of the services we provide. PeFUD operates with integrity.</div>
        </article>
      </section>
      {/* second */}
    </>
  );
};
export default Donation;
