import { useEffect, useState } from 'react';
import { NFT, StakingNFT } from '../pages/Mint';
import NftList from './NftList';
import StakingNftItem from './StakingNftItem';

type StakingNftListProps = {
  setNftList: React.Dispatch<React.SetStateAction<NFT[]>>;
  stakingNftList: StakingNFT[];
  setStakingNftList: React.Dispatch<React.SetStateAction<StakingNFT[]>>;
};

const StakingNftList: React.FC<StakingNftListProps> = ({ setNftList, stakingNftList, setStakingNftList }: StakingNftListProps): JSX.Element => {
  const [totalStakingTime, setTotalStakingTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {}, 10);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className='w-[100%] font-roboto text-[5rem] font-[800] mb-[5rem] flexRow l:text-[4rem] l:mb-[4rem] p:text-[3rem] p:mb-[3rem] t:text-[4vw] t:mb-[4vw]'>STAKED NFT</div>
      <div className='w-[100%] flex flex-wrap pb-[10rem] l:flexRow l:justify-start l:pb-[6rem] p:pb-[4rem] t:pb-[4vw]'>
        {stakingNftList.map((v, i) => (
          <StakingNftItem key={v?.nftTokenId + i} id={v?.nftTokenId} imageUrl={v?.imageUrl} stakingTimestamp={v?.stakingTimestamp} setNftList={setNftList} setStakingNftList={setStakingNftList} />
        ))}
      </div>
    </>
  );
};

export default StakingNftList;
