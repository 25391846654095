import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';

import '../scss/history.scss';

import { CustomArrowProps } from 'react-slick';
import { useEffect, useRef, useState } from 'react';
import { ReactTyped } from 'react-typed';
import { useNavigate } from 'react-router-dom';

const CustomNextArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', right: '10px', zIndex: 10, width: '3.5rem' }} onClick={onClick}>
      <div className='w-[3.5rem] p:w-[2rem] h-[3.5rem] p:w-[2rem] bg-center bg-no-repeat bg-contain l:translate-y-[-3rem] p:translate-y-[-11rem]' style={{ backgroundImage: 'url("/images/arrow-white.png")' }}></div>
    </div>
  );
};

const CustomPrevArrow: React.FC<CustomArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', left: '10px', zIndex: 10, width: '3.5rem' }} onClick={onClick}>
      <div className='w-[3.5rem] p:w-[2rem] h-[3.5rem] p:h-[2rem] bg-center bg-no-repeat bg-contain rotate-180 l:translate-y-[-3rem] p:translate-y-[-11rem]' style={{ backgroundImage: 'url("/images/arrow-white.png")' }}></div>
    </div>
  );
};

const History = () => {
  const [timerHandle, setTimerHandle] = useState(70);
  const [hiddenHandle, setHiddenHandle] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const startHistoryRef = useRef<HTMLDivElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [showSub, setShowSub] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = 'black';
      body.style.position = 'relative';
    }

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      navigate('/m-history');
    }
  }, [navigate]);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    nextArrow: <CustomNextArrow></CustomNextArrow>,
    prevArrow: <CustomPrevArrow></CustomPrevArrow>
  };

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = '#000';
    }
    const timer = setInterval(() => {
      setTimerHandle((prev) => (prev + 1 >= 74 ? 70 : prev + 1));
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHiddenHandle(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDivClick = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleClickStartHistory = () => {
    const audio = audioRef.current;

    const startHistory = startHistoryRef.current;

    if (startHistory) startHistory.style.display = 'none';

    if (audio) {
      audio.play();
      setIsPlaying(true);
    }
  };

  const handleMouseEnterAudio = () => {
    setShowSub(true);
  };

  const handleMouseLeaveAudio = () => {
    setShowSub(false);
  };

  const handleClickGoDonate = () => {
    navigate('/donation');
  };
  return (
    <div className='flexCol justify-start w-[100vw] d:min-w-[1240px] l:min-w-[1040px] p:min-w-[790px] t:min-w-[680px] d:min-h-[700px] h-[100vh] l:min-h-[550px] p:min-h-[400px] pt-[3rem] l:h-[100vh] p:h-[100vh] t:h-[100vh] p:pt-[0rem] t:pt-[0rem] l:pt-[0rem] t:hidden overflow-scroll'>
      <div className={`z-100 w-100% h-100vh fixed top-0 left-0 bg-[#FFC82C] flexRow z-300 ${hiddenHandle ? 'hidden' : ''}`}>
        <div className='w-100% h-100% relative flexRow'>
          <div className='w-[120rem] h-[50rem] flexRow pb-[3rem] p:hidden'>
            <h2 className='text-[6rem] l:text-[6rem] font-[800] text-black pr-[0.3rem]'>LOADING</h2>
            <ReactTyped strings={['...']} typeSpeed={350} backSpeed={0} backDelay={350} showCursor={false} loop={true} style={{ fontSize: '6rem', color: 'black', fontFamily: 'roboto', fontWeight: '800', paddingBottom: '0.5rem' }} />
          </div>
          <div className='hidden p:flexRow'>
            <h2 className='text-[3rem] font-[800] text-black pr-[0.3rem]'>LOADING</h2>
            <ReactTyped strings={['...']} typeSpeed={350} backSpeed={0} backDelay={350} showCursor={false} loop={true} style={{ fontSize: '3rem', color: 'black', fontFamily: 'roboto', fontWeight: '800', paddingBottom: '0.5rem' }} />
          </div>
          <div className='w-[20rem] h-[20rem] animate-[show-left-and-remove_5s_linear_infinite] top-[45%] absolute left-[-30rem]'>
            <div className='bg-contain bg-center bg-no-repeat w-[20rem] h-[20rem] animate-[bounce-wobble_0.4s_linear_infinite]' style={{ backgroundImage: `url("/images/${timerHandle}.png")` }}></div>
          </div>
          <div className='w-[20rem] h-[20rem] animate-[show-left-and-remove_5s_linear_infinite] top-[50%] absolute left-[-20rem]'>
            <div className='bg-contain bg-center bg-no-repeat w-[10rem] h-[10rem] animate-[wobble-ver-left_5s_linear_infinite]' style={{ backgroundImage: `${timerHandle === 73 ? 'url("/images/74.png")' : 'url("/images/bone.png")'}` }}></div>
          </div>
          <div className='absolute bottom-[-1rem] bg-center bg-no-repeat bg-contain w-[10rem] h-[10rem]' style={{ backgroundImage: 'url("/images/88.png")' }}></div>
        </div>
      </div>
      <div className='z-200 w-[100%] h-100vh fixed top-[0] left-[0] bg-[black] flexCol cursor-pointer' onClick={handleClickStartHistory} ref={startHistoryRef}>
        <div className='text-white text-[7rem] l:text-[5rem] p:text-[3.5rem] t:text-[4vw] font-[800] font-pixelify'>Click to start!</div>
        <div className='text-white text-[3.5rem] l:text-[3rem] p:text-[2rem] border border-white rounded-[0.3rem] font-[800] border-solid font-roboto py-[0.5rem] px-[2rem] mt-[5rem] p:mt-[3rem] cursor-pointer border-[0.2rem] font-pixelify'>click</div>
      </div>
      <div className='w-[5rem] p:w-[4rem] h-[5rem] p:h-[4rem] fixed bottom-[1rem] left-[1rem] z-100 bg-contain bg-center bg-no-repeat animate-[rotate-record_1s_linear_infinite] cursor-pointer' style={{ backgroundImage: 'url("/images/83.png")' }} onClick={handleDivClick} onMouseEnter={handleMouseEnterAudio} onMouseLeave={handleMouseLeaveAudio}>
        <audio ref={audioRef} src='/music/a.mp4' />
      </div>
      {showSub ? (
        <>
          <div className='fixed bottom-[6rem] p:bottom-[5rem] left-[0rem] w-[28rem] h-[30rem] bg-black z-100 overflow-scroll py-[1rem] rounded-[0.5rem]' onMouseEnter={handleMouseEnterAudio} onMouseLeave={handleMouseLeaveAudio}>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>In the fields of battle, brave and strong,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>PEFUD stood with comrades, where he belonged.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>With courage in his heart and love so true,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>He saved many lives, fought the battles through.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, the dream we chase,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For a brighter future, a safer place.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, where hopes align,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For the dogs who served, a life divine.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>But the shadows grew long, and the nights turned cold,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Witnessing the fallen, stories left untold.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>From the heart of a warrior to a healer’s embrace,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>PEFUD sought a path, a more gentle place.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Retired from the war, he found a new way,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Helping injured dogs, bringing light to their day.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Fulfillment in his heart, happiness in his eyes,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Yet the struggle for support brought forth heavy sighs.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, the dream we chase,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For a brighter future, a safer place.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, where hopes align,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For the dogs who served, a life divine.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Frustration grew, funds were low,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Crypto dreams seemed the way to go.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>$ZEN was his hope, but it fell from grace,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Meme coins soared, left him in a daze.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Invested all he had, in hopes to see,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>A future bright for the dogs, living free.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>But losses came, his savings turned to dust,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Changing him slowly, in appearance and trust.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, the dream we chase,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For a brighter future, a safer place.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>ZEN, ZEN, where hopes align,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>For the dogs who served, a life divine.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>But the shadows grew long, and the nights turned cold,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Witnessing the fallen, stories left untold.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>From the heart of a warrior to a healer’s embrace,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>PEFUD sought a path, a more gentle place.</div>
            <div className='text-white text-center text-[1rem] mb-[0.8rem]'></div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Now as Pepe, he stands tall,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>Immortalized in art, he gave it his all.</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>A warrior’s journey, a tale so deep,</div>
            <div className='text-white text-center text-[1rem] mb-[0.15rem]'>In NFTs, his legacy we keep.</div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] bg-black text-content t:hidden d:mb-[0rem] overflow-hidden'>
        <Slider {...settings}>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[31.5rem] l:translate-y-[27.5rem] p:translate-y-[19.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-[600] font-roboto'>PeFUD was originally a brave and strong military dog who loved his comrades dearly and won battle. He had various combat experiences and saved many casualties.</div>
          <div>
            <img src='/images/story01.png' alt='Story 1' />
          </div>
          <div>
            <img src='/images/story02.png' alt='Story 2' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[30.5rem] l:translate-y-[26.5rem] p:translate-y-[17.7rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>However, after experiencing numerous battles and witnessing many of his beloved comrades die in combat, he felt an immense sadness. This led him to want to help injured and retired military dogs who had endured hardships, rather than continue fighting.</div>
          <div>
            <img src='/images/story03.png' alt='Story 3' />
          </div>
          <div>
            <img src='/images/story04.png' alt='Story 4' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[31.5rem] l:translate-y-[27.5rem] p:translate-y-[19.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>After retiring, PeFUD began to diligently help other injured and retired military dogs. This brought him a great sense of fulfillment and happiness.</div>
          <div>
            <img src='/images/story05.png' alt='Story 5' />
          </div>
          <div>
            <img src='/images/story06.png' alt='Story 6' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[31.5rem] l:translate-y-[27.5rem] p:translate-y-[19.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>As time went by, however, PeFUD realized how inadequate the treatment and welfare of retired and injured military dogs were. He felt frustrated by his inability to make a significant difference due to a lack of funds.</div>
          <div>
            <img src='/images/story07.png' alt='Story 7' />
          </div>
          <div>
            <img src='/images/story08.png' alt='Story 8' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[29rem] l:translate-y-[25.1rem] p:translate-y-[17.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>Feeling that retired and injured military dogs needed financial support, he decided to invest in crypto to earn a substantial amount of money. He invested in $ZEN, but the value of $ZEN kept falling while only meme coins were increasing in price, leaving him disheartened and frustrated.</div>
          <div>
            <img src='/images/story09.png' alt='Story 9' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[29rem] l:translate-y-[25.1rem] p:translate-y-[17.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>As a result, PeFUD started focusing heavily on investing in meme coins. However, the continuous investments, especially putting his retirement savings into $pepe coin, led to significant losses. Over time, these losses began to change his appearance, and eventually, he transformed to resemble Pepe.</div>
          <div>
            <img src='/images/story10.jpg' alt='Story 10' />
          </div>
          <div>
            <img src='/images/story11.jpg' alt='Story 11' />
          </div>
          <div>
            <img src='/images/story12.jpg' alt='Story 12' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[32.9rem] l:translate-y-[28.8rem] p:translate-y-[20.7rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>Our Proud hero PeFUD eventually transformed from a hero into a meme coin monster.</div>
          <div>
            <img src='/images/story13.jpg' alt='Story 13' />
          </div>
          <div>
            <img src='/images/story14.png' alt='Story 14' />
          </div>
          <div className='w-[1200px] l:w-[1000px] p:w-[750px] h-[652px] l:h-[520px] p:h-[340px] text-[4rem] l:text-[3rem] p:text-[2.3rem] px-[6rem] p:px-[5rem] text-center text-white translate-y-[32.9rem] l:translate-y-[28.8rem] p:translate-y-[19.5rem] leading-[4.5rem] l:leading-[3.5rem] p:leading-[3rem] font-roboto font-[600]'>And so, the corrupted PeFUD decided to immortalize his appearance as an NFT on the horizen EON chain block forever.</div>
          <div className='w-[1200px] h-[652px] text-[1.8rem] px-[6rem] text-center text-white translate-y-[31.8rem] l:translate-y-[27.4rem] p:translate-y-[19.4rem]'>
            <span className='text-orange font-roboto text-[6rem] p:text-[4rem] font-[800] pr-[0.7rem]'>NFT</span>
            <span className='text-white font-roboto text-[6rem] p:text-[4rem] font-[800]'>Coming soon!!</span>
          </div>
          <div>
            <img src='/images/story15.jpg' alt='Story 15' />
          </div>
          <div className='w-[1200px] h-[652px] text-[1.8rem] px-[6rem] text-center text-white translate-y-[28.5rem] l:translate-y-[24rem] p:translate-y-[15.5rem] leading-[2.5rem]'>
            <div className='text-white text-[6rem] font-[800] font-pixelify'>Go to donate?</div>
            <div className='flexRow width-[100%]'>
              <div className='text-white text-[3rem] w-[13rem] h-[4rem] text-center leading-[3.9rem] mt-[7rem] border border-white border-solid rounded-[0.5rem] font-[800] font-pixelify cursor-pointer' onClick={handleClickGoDonate}>
                click
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default History;
