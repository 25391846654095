import React, { useState, useEffect } from 'react';
import Container from '../layouts/Container';
import Section from '../layouts/Section';
import { ethers, InterfaceAbi, toBeHex } from 'ethers';
import { createRippleEffect } from '../utils/createRippleEffect';
import axios from 'axios';
import DonationHeader from '../layouts/DonationHeader';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../stores/store';
import { contractAddress, dev, getBasicStakingNfts, getNFTs, requestBonePoints, requestMint } from '../services';
import { setBonesPoint, setChainId, setPermissionAddresses, setWalletAddress } from '../stores/walletSlice';
import { contractABI } from '../utils/contractABI';
import Footer from '../layouts/Footer';
import Portal from '../components/Portal';
import { ReactTyped } from 'react-typed';
import If from '../components/If';
import NftList from '../components/NftList';
import GachaWheel from '../components/GachaWheel';
import { useNavigate } from 'react-router-dom';
import StakingNftList from '../components/StakingNftList';
import { resourceUsage } from 'process';

export type NFT = {
  id: number;
  imageUrl: string;
  revealed: boolean;
  approved: boolean;
};

export type StakingNFT = {
  nftTokenId: string;
  imageUrl: string;
  stakingTimestamp: string;
  bonesClaimable: string;
  bonesClaimed: string;
};

const Mint = () => {
  const navigate = useNavigate();
  const websocketURL = process.env.REACT_APP_WEB_SOCKET ?? '';

  const { chainId, walletAddress, permissionAddresses, bonesPoint } = useSelector((state: RootState) => state.wallet);

  const dispatch: AppDispatch = useDispatch();

  const [rouletteResult, setRouletteResult] = useState('Not allowed.');

  const [nftList, setNftList] = useState<NFT[]>([]);

  const [basicStakingNftList, setBasicStakingNftList] = useState<StakingNFT[]>([]);

  const [leftDays, setLeftDays] = useState(0);
  const [leftHours, setLeftHours] = useState(0);
  const [leftMinutes, setLeftMinutes] = useState(0);
  const [leftSeconds, setLeftSeconds] = useState(0);

  const [handlerModal, setHandlerModal] = useState(false);

  const [handleReveal, setHandleReveal] = useState(false);

  const [mintingStatus, setMintingStatus] = useState('');

  const [hoursPassed, setHoursPassed] = useState(0);

  const [leftMinutesForIncreasingPrice, setLeftMinutesForIncreasingPrice] = useState(0);
  const [leftSecondsForIncreasingPrice, setLeftSecondsIncreasingPrice] = useState(0);

  const [ableMintingNftAmount, setAbleMintingAmount] = useState(0);
  const [totalSupplyNftAmount, setTotalSupplyNftAmount] = useState(0);

  const [leftDaysForMinting, setLeftDaysForMinting] = useState(0);
  const [leftHoursForMinting, setLeftHoursForMinting] = useState(0);
  const [leftMinutesForMinting, setLeftMinutesForMinting] = useState(0);
  const [leftSecondsForMinting, setLeftSecondsForMinting] = useState(0);

  const [startMintHandler, setStartMintHandler] = useState(false);
  const [endMintHandler, setEndMintHandler] = useState(false);

  const [nowTime, setNowTime] = useState(0);
  const [standardTime, setStandardTime] = useState(0);

  const init = () => {
    dispatch(setWalletAddress(''));
    dispatch(setPermissionAddresses([]));
    dispatch(setBonesPoint('0'));
    dispatch(setChainId(-1));
    setRouletteResult('Not allowed.');
  };

  useEffect(() => {
    const utcTimeZone = 'UTC';

    const calculateTimeLeft = () => {
      const nowTime = moment().tz(utcTimeZone);
      const startTime = moment.tz('2024-09-09 13:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);
      const endTime = moment.tz('2024-10-03 10:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);

      const revealStartTime = moment.tz('2024-09-01 12:00:00', 'YYYY-MM-DD HH:mm:ss', utcTimeZone);

      const diffMillisecondsForReveal = nowTime.diff(revealStartTime);

      setHandleReveal(diffMillisecondsForReveal > 0 ? true : false);

      const diffMillisecondsForMinting = startTime.diff(nowTime);

      if (diffMillisecondsForMinting <= 0) {
        setStartMintHandler(true);
      }

      const leftDaysForMinting = Math.floor(diffMillisecondsForMinting / (1000 * 60 * 60 * 24));
      const leftHoursForMinting = Math.floor((diffMillisecondsForMinting % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const leftMinutesForMinting = Math.floor((diffMillisecondsForMinting % (1000 * 60 * 60)) / (1000 * 60));
      const leftSecondsForMinting = Math.floor((diffMillisecondsForMinting % (1000 * 60)) / 1000);

      setLeftDaysForMinting(leftDaysForMinting);
      setLeftHoursForMinting(leftHoursForMinting);
      setLeftMinutesForMinting(leftMinutesForMinting);
      setLeftSecondsForMinting(leftSecondsForMinting);

      const diffMilliseconds = endTime.diff(nowTime);

      if (diffMilliseconds < 0) {
        // setEndMintHandler(true);
        setEndMintHandler(false);
      }

      const leftDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
      const leftHours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const leftMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const leftSeconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

      setNowTime(nowTime.valueOf() - startTime.valueOf());
      setStandardTime(endTime.valueOf() - startTime.valueOf());

      setLeftDays(leftDays);
      setLeftHours(leftHours);
      setLeftMinutes(leftMinutes);
      setLeftSeconds(leftSeconds);
    };

    // const test = async () => {
    //   if (walletAddress === '') return;
    //   const provider = new ethers.BrowserProvider(window.ethereum);
    //   const signer = await provider.getSigner();
    //   const contract = new ethers.Contract(contractAddress, contractABI, signer);
    //   const authAddress = await contract.authAddress(walletAddress);

    //   console.log(authAddress);
    // };

    calculateTimeLeft();
    // test();

    const intervalId = setInterval(() => {
      calculateTimeLeft();
      // test();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [walletAddress]);

  useEffect(() => {
    const getAmount = async () => {
      if (window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        const totalSupply = await contract.maxSupply().catch((err) => console.log(err));
        const mintAmount = await contract.totalSupply().catch((err) => console.log(err));

        setTotalSupplyNftAmount(parseInt(totalSupply));
        setAbleMintingAmount(parseInt(mintAmount ?? 0));
      }
    };
    getAmount();
    const intervalId = setInterval(() => {
      getAmount();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken') ?? '';
    const refreshToken = localStorage.getItem('refreshToken') ?? '';

    if (walletAddress === '' || accessToken === '' || refreshToken === '') return;

    const initRouletteResult = async () => {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
        return;
      }

      await axios
        .post(dev + '/api/minting/rouletteResult', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
        .then(async (res) => {
          const { rouletteResult } = res.data;

          await contract
            .authAddress(walletAddress.toLocaleLowerCase())
            .then(async (res) => {
              const resultOfAuthAddrAtTx = res;

              if (rouletteResult === true && resultOfAuthAddrAtTx === true) {
                setRouletteResult('Allowed.');
                return;
              }
              if (rouletteResult === false && resultOfAuthAddrAtTx === true) {
                setRouletteResult('Pending.');
                return;
              }
              if (rouletteResult === false && resultOfAuthAddrAtTx === false) {
                setRouletteResult('Not allowed.');
                return;
              }
              if (rouletteResult === true && resultOfAuthAddrAtTx === false) {
                // await axios
                //   .post(dev + '/api/minting/mint', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                //   .then(async () => {
                //     setRouletteResult('Not allowed.');
                //   })
                //   .catch(async (err) => {
                //     if (err.response.data.message === 'Generate Token.') {
                //       const accessToken = err.response.data.accessToken;
                //       const refreshToken = err.response.data.refreshToken;

                //       localStorage.setItem('accessToken', accessToken);
                //       localStorage.setItem('refreshToken', refreshToken);

                //       await axios
                //         .post(dev + '/api/minting/mint', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                //         .then(async () => {
                //           setRouletteResult('Not allowed.');
                //         })
                //         .catch((err) => console.log(err));
                //       return;
                //     }
                //     if (err.response.data.message === 'Refresh token has expired.') {
                //       if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                //       else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                //       return;
                //     }
                //     if (err.response.data.message === 'Token error.') {
                //       if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                //       else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                //       return;
                //     }
                //   });
                return;
              }
            })
            .catch((err) => console.log(err.toString()));
        })
        .catch(async (err) => {
          console.log(err);
          if (err.response.data.message === 'Generate Token.') {
            const accessToken = err.response.data.accessToken;
            const refreshToken = err.response.data.refreshToken;

            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            await axios
              .post(dev + '/api/minting/rouletteResult', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
              .then(async (res) => {
                const { rouletteResult } = res.data;

                await contract
                  .authAddress(walletAddress)
                  .then(async (res) => {
                    const resultOfAuthAddrAtTx = res;

                    if (rouletteResult === true && resultOfAuthAddrAtTx === true) {
                      setRouletteResult('Allowed.');
                      return;
                    }
                    if (rouletteResult === false && resultOfAuthAddrAtTx === true) {
                      setRouletteResult('Pending.');
                      return;
                    }
                    if (rouletteResult === false && resultOfAuthAddrAtTx === false) {
                      setRouletteResult('Not allowed.');
                      return;
                    }
                    if (rouletteResult === true && resultOfAuthAddrAtTx === false) {
                      // await axios
                      //   .post(dev + '/api/minting/mint', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                      //   .then(async () => {
                      //     setRouletteResult('Not allowed.');
                      //   })
                      //   .catch((err) => console.log(err));
                      return;
                    }
                  })
                  .catch((err) => console.log(err.toString()));
              })
              .catch((err) => console.log(err.toString()));
            return;
          }
          if (err.response.data.message === 'Refresh token has expired.') {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
            else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
            return;
          }
          if (err.response.data.message === 'Token error.') {
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
            else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
            return;
          }
        });
    };

    initRouletteResult();
  }, [dispatch, walletAddress]);

  const handleClickMint = async () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (startMintHandler === false || endMintHandler === true) {
      alert('Mints can only be made during the designated minting period.');
      return;
    }
    if (!window.ethereum) {
      alert('MetaMask is not connected.');
      return;
    }
    if (walletAddress === '' || accessToken === null || refreshToken === null) {
      alert('Please connect your wallet to the EON CHAIN.');
      return;
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    const totalSupply = await contract.maxSupply().catch((err) => console.log(err));
    const mintAmount = await contract.totalSupply().catch((err) => console.log(err));

    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
      alert('Please switch to the EON CHAIN.');
      return;
    }

    if (mintAmount !== 0 && mintAmount >= totalSupply) {
      alert("There are too many people who have minted, so you can't mint anymore.");
      return;
    }

    await axios
      .post(dev + '/api/minting/rouletteResult', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
      .then(async (res) => {
        const { rouletteResult } = res.data;

        await contract
          .authAddress(walletAddress)
          .then(async (res) => {
            const resultOfAuthAddrAtTx = res;

            if (rouletteResult === false || resultOfAuthAddrAtTx === false) {
              setRouletteResult(rouletteResult === true && resultOfAuthAddrAtTx === true ? 'Allowed.' : rouletteResult === false && resultOfAuthAddrAtTx === true ? 'Pending.' : 'Not allowed.');
              if (rouletteResult === false && resultOfAuthAddrAtTx === true) {
                alert('Minting is in progress.');
              }
              return;
            }
            if (rouletteResult === true && resultOfAuthAddrAtTx === true) {
              setHandlerModal(true);

              return;
            }
          })
          .catch((err) => console.log(err.toString()));
      })
      .catch(async (err) => {
        if (err.response.data.message === 'Generate Token.') {
          const accessToken = err.response.data.accessToken;
          const refreshToken = err.response.data.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          await axios
            .post(dev + '/api/minting/rouletteResult', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
            .then(async (res) => {
              const { rouletteResult } = res.data;

              await contract
                .authAddress(walletAddress)
                .then(async (res) => {
                  const resultOfAuthAddrAtTx = res;

                  if (rouletteResult === false || resultOfAuthAddrAtTx === false) {
                    setRouletteResult(rouletteResult === true && resultOfAuthAddrAtTx === true ? 'Allowed.' : rouletteResult === false && resultOfAuthAddrAtTx === true ? 'Pending.' : 'Not allowed.');
                    if (rouletteResult === false && resultOfAuthAddrAtTx === true) {
                      alert('Minting is in progress.');
                    }
                    return;
                  }
                  if (rouletteResult === true && resultOfAuthAddrAtTx === true) {
                    setHandlerModal(true);

                    return;
                  }
                })
                .catch((err) => alert(err.toString()));
            })
            .catch((err) => alert(err.toString()));
          return;
        }
        if (err.response.data.message === 'Refresh token has expired.') {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
          return;
        }
        if (err.response.data.message === 'Token error.') {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
          return;
        }
      });
  };

  const printStrTime = (time: number) => {
    return time < 10 ? '0' + time : time;
  };

  useEffect(() => {
    const initNFTs = async () => {
      if (walletAddress !== '' && window.ethereum) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();

        if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
          return;
        }

        const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

        setNftList(nftList);
      } else {
        return;
      }
    };

    initNFTs();

    const initStakingNFTs = async () => {
      if (!window.ethereum || walletAddress === '') return;

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();

      if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
        return;
      }

      await getBasicStakingNfts(walletAddress).then((res) => {
        if (res === undefined) return;
        else {
          setBasicStakingNftList(res);
        }
      });
    };

    initStakingNFTs();
  }, [walletAddress, chainId]);

  const handleClickConfirmMinting = async () => {
    setHandlerModal(false);
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    await axios
      .post(dev + '/api/minting/mint', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
      .then(async () => {
        setRouletteResult('Pending.');

        await contract
          .Mint(walletAddress, 1, { value: 0 })
          .then(async (res) => {
            const txHash = res;

            await txHash
              .wait()
              .then(async () => {
                const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];
                setNftList(nftList);
                setRouletteResult('Not allowed.');
                const getAmount = async () => {
                  if (window.ethereum) {
                    const totalSupply = await contract.maxSupply().catch((err) => console.log(err));
                    const mintAmount = await contract.totalSupply().catch((err) => console.log(err));

                    setTotalSupplyNftAmount(parseInt(totalSupply));
                    setAbleMintingAmount(parseInt(mintAmount));
                  }
                };

                await getAmount();
              })
              .catch(async (err: any) => {
                await axios
                  .post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                  .then(() => {
                    alert('Minting Failed.');
                    setRouletteResult('Allowed.');
                  })
                  .catch(async (err) => {
                    if (err.response.data.message === 'Generate Token.') {
                      const accessToken = err.response.data.accessToken;
                      const refreshToken = err.response.data.refreshToken;

                      localStorage.setItem('accessToken', accessToken);
                      localStorage.setItem('refreshToken', refreshToken);

                      await axios.post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).then(() => {
                        alert('Minting Failed.');
                        setRouletteResult('Allowed.');
                      });
                    }
                    if (err.response.data.message === 'Refresh token has expired.') {
                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                      return;
                    }
                    if (err.response.data.message === 'Token error.') {
                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                      return;
                    }
                  });
                console.log(err.toString());
              });
          })
          .catch(async (err: any) => {
            await axios
              .post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
              .then(() => {
                alert('Minting has been canceled.');
                setRouletteResult('Allowed.');
              })
              .catch(async (err) => {
                if (err.response.data.message === 'Generate Token.') {
                  const accessToken = err.response.data.accessToken;
                  const refreshToken = err.response.data.refreshToken;

                  localStorage.setItem('accessToken', accessToken);
                  localStorage.setItem('refreshToken', refreshToken);

                  await axios.post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).then(() => {
                    alert('Minting Failed.');
                    setRouletteResult('Allowed.');
                  });
                }
                if (err.response.data.message === 'Refresh token has expired.') {
                  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                  else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                  return;
                }
                if (err.response.data.message === 'Token error.') {
                  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                  else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                  return;
                }
              });
            console.log(err.toString());
          });
      })
      .catch(async (err) => {
        if (err.response.data.message === 'Generate Token.') {
          const accessToken = err.response.data.accessToken;
          const refreshToken = err.response.data.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          await axios.post(dev + '/api/minting/mint', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).then(async () => {
            await contract
              .Mint(walletAddress, 1, { value: 0 })
              .then(async (res) => {
                const txHash = res;

                await txHash
                  .wait()
                  .then(async () => {
                    const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];
                    setNftList(nftList);
                    setRouletteResult('Not allowed.');
                    const getAmount = async () => {
                      if (window.ethereum) {
                        const totalSupply = await contract.maxSupply().catch((err) => console.log(err));
                        const mintAmount = await contract.totalSupply().catch((err) => console.log(err));

                        setTotalSupplyNftAmount(parseInt(totalSupply));
                        setAbleMintingAmount(parseInt(mintAmount));
                      }
                    };

                    await getAmount();
                  })
                  .catch(async (err: any) => {
                    await axios
                      .post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                      .then(() => {
                        alert('Minting Failed.');
                        setRouletteResult('Allowed.');
                      })
                      .catch(async (err) => {
                        if (err.response.data.message === 'Generate Token.') {
                          const accessToken = err.response.data.accessToken;
                          const refreshToken = err.response.data.refreshToken;

                          localStorage.setItem('accessToken', accessToken);
                          localStorage.setItem('refreshToken', refreshToken);

                          await axios.post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).then(() => {
                            alert('Minting Failed.');
                            setRouletteResult('Allowed.');
                          });
                        }
                        if (err.response.data.message === 'Refresh token has expired.') {
                          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                          return;
                        }
                        if (err.response.data.message === 'Token error.') {
                          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                          return;
                        }
                      });
                    console.log(err.toString());
                  });
              })
              .catch(async (err: any) => {
                await axios
                  .post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
                  .then(() => {
                    alert('Minting has been canceled.');
                    setRouletteResult('Allowed.');
                  })
                  .catch(async (err) => {
                    if (err.response.data.message === 'Generate Token.') {
                      const accessToken = err.response.data.accessToken;
                      const refreshToken = err.response.data.refreshToken;

                      localStorage.setItem('accessToken', accessToken);
                      localStorage.setItem('refreshToken', refreshToken);

                      await axios.post(dev + '/api/minting/mintError', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } }).then(() => {
                        alert('Minting Failed.');
                        setRouletteResult('Allowed.');
                      });
                    }
                    if (err.response.data.message === 'Refresh token has expired.') {
                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                      return;
                    }
                    if (err.response.data.message === 'Token error.') {
                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                      return;
                    }
                  });
                console.log(err.toString());
              });
          });
        }
        if (err.response.data.message === 'Refresh token has expired.') {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
          return;
        }
        if (err.response.data.message === 'Token error.') {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
          return;
        }
        console.log(err);
      });
  };

  const handleClickNavigateDonation = () => {
    navigate('/donation');
  };

  const handleClickStory = () => {
    navigate('/history');
  };
  return (
    <>
      <DonationHeader firstLogo='PeFUD' secondLogo='NFT' />
      <section className='w-[100%] flexCol pt-[18rem] l:pt-[15rem] p:pt-[10rem] t:pt-[15vw]'>
        <article className='max-w-[120rem] w-[100%] flexCol pb-[10rem] l:pb-[6rem] p:pb-[4rem] t:pb-[6vw]'>
          <h1 className='text-[15rem] l:text-[12rem] leading-[15rem] l:leading-[12rem] font-impact my-[3rem] l:my-[2.5rem] font-[900] text-red tracking-[0rem] z-100 d:[text-shadow:0px_4px_4px_#000000] l:[text-shadow:0px_3px_3px_#000000] d:[-webkit-text-stroke:10px_#000000] l:[-webkit-text-stroke:8px_#000000] l:pt-[3rem] p:text-[7rem] p:leading-[7rem] p:my-[2rem] p:[text-shadow:0px_2px_2px_#000000] p:[-webkit-text-stroke:5px_#000000] t:text-[15vw] t:leading-[15vw] t:mt-[4vw] t:mb-[0vw]  t:[text-shadow:0px_0.7vw_0.7vw_#000000] t:[-webkit-text-stroke:0.7vw_#000000]'>GET YOUR</h1>
          <h1 className='text-[15rem] l:text-[12rem] leading-[15rem] l:leading-[10rem] font-impact font-[900] text-red tracking-[0rem] z-100 d:[text-shadow:0px_4px_4px_#000000] l:[text-shadow:0px_3px_3px_#000000] d:[-webkit-text-stroke:10px_#000000] l:[-webkit-text-stroke:8px_#000000] p:text-[7rem] p:leading-[7rem] p:[text-shadow:0px_2px_2px_#000000] p:[-webkit-text-stroke:5px_#000000] t:text-[15vw] t:leading-[15vw] t:[text-shadow:0px_0.7vw_0.7vw_#000000] t:[-webkit-text-stroke:0.7vw_#000000] pb-[6rem] p:pb-[4rem] t:pb-[6vw]'>NFT</h1>
          <div className='max-w-[120rem] w-[100%] flexRow relative'>
            <div className='flexCol items-start absolute top-[28rem] left-[4rem] l:left-[4rem] l:top-[17.5rem] p:left-[4rem] p:top-[11.8rem] t:left-[4vw] t:top-[17vw] pr-[4rem] t:pr-[4vw]'>
              <div className='font-roboto text-[2.4rem] font-[800] leading-[2.4rem] [-webkit-text-stroke:1.5px_#000000] l:[-webkit-text-stroke:1.2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:[-webkit-text-stroke:0.15vw_#000000] text-white pb-[0.7rem] l:text-[2.1rem] l:leading-[2.1rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.5rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw]'>THE MOST SUCCESSFUL</div>
              <div className='font-roboto text-[2.4rem] font-[800] leading-[2.4rem] [-webkit-text-stroke:1.5px_#000000] l:[-webkit-text-stroke:1.2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:[-webkit-text-stroke:0.15vw_#000000] text-white pb-[0.7rem] l:text-[2.1rem] l:leading-[2.1rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.5rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw]'>PROJECT ON HORIZEN, PeFUD,</div>
              <div className='font-roboto text-[2.4rem] font-[800] leading-[2.4rem] [-webkit-text-stroke:1.5px_#000000] l:[-webkit-text-stroke:1.2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:[-webkit-text-stroke:0.15vw_#000000] text-white pb-[0.7rem] l:text-[2.1rem] l:leading-[2.1rem] p:text-[1.6rem] p:leading-[1.6rem] p:pb-[0.5rem] t:text-[2vw] t:leading-[2vw] t:pb-[1.5vw]'>IS LAUNCHING ITS NFT!</div>
              <div className='font-roboto text-[2.4rem] font-[800] leading-[2.4rem] [-webkit-text-stroke:1.5px_#000000] l:[-webkit-text-stroke:1.2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:[-webkit-text-stroke:0.15vw_#000000] text-red l:text-[2.1rem] l:leading-[2.1rem] p:text-[1.6rem] p:leading-[1.6rem] t:text-[2vw] t:leading-[2vw]'>SEPTEMBER 09, 10:00 UTC</div>
            </div>
            <div className='w-[40rem] h-[40rem] ml-[2rem] l:ml-[1rem] p:ml-[0.5rem] t:ml-[1vw] rounded-[0.5rem] border-[0.24rem] bg-no-repeat bg-cover bg-center animate-[heartbeat_1s_infinite] l:w-[30rem] l:h-[30rem] l:border-[0.21rem] p:w-[20rem] p:h-[20rem] p:border-[0.15rem] t:w-[30vw] t:h-[30vw] t:rounded-[0.5vw] t:border-[0.1rem]' style={{ backgroundImage: 'url("/images/roadmap.png")' }}></div>
          </div>
        </article>
      </section>
      <section className='w-[100%] flexCol bg-dark-gray t:h-[45vw] py-[10rem] l:py-[6rem] p:py-[3rem] t:py-[0]'>
        <article className='max-w-[120rem] flexCol w-[100%]'>
          <h2 className='text-[6.5rem] font-oswald pt-[2.2rem] leading-[6.5rem] font-[800] text-red animate-[toggle-text-yellow_1s_infinite] l:text-[5rem] l:leading-[5rem] p:text-[4rem] p:leading-[4rem] t:text-[5vw] t:leading-[5vw]'>MEMORIALIZE PEFUD BY OWNING NFT.</h2>
          <div className='w-[100%] flexCol pt-[10rem] pb-[2.7rem] l:pt-[6rem] l:pb-[1.5rem] p:pt-[4rem] p:pb-[2rem] t:pb-[4vw] t:pt-[5vw]'>
            <div className='text-white font-roboto text-[2.15rem] font-[600] l:text-[1.7rem] p:text-[1.3rem] p:font-[500] t:font-[500] t:text-[1.7vw]'>Our brave hero PeFUD made a big bet on Horizen, but after failing, he went all-in on meme coins and</div>
            <div className='text-white font-roboto text-[2.15rem] font-[600] l:text-[1.7rem] p:text-[1.3rem] p:font-[500] t:font-[500] t:text-[1.7vw]'>ended up being liquidated. Overcome with sorrow and despair, he transformed into a Coin Monster. Keep the memory of</div>
            <div className='text-white font-roboto text-[2.15rem] font-[600] l:text-[1.7rem] p:text-[1.3rem] p:font-[500] t:font-[500] t:text-[1.7vw] pb-[5rem] t:pb-[5vw]'>our hero PeFUD alive by owning this NFT. Curious about PeFUD's full history?</div>
            <div className='text-white font-roboto text-[2.6rem] font-[600] l:text-[2.1rem] p:text-[1.6rem] t:text-[2vw] cursor-pointer border-white border-[0.24rem] l:border-[0.21rem] p:border-[0.15rem] t:border-[0.1rem] px-[3.5rem] l:px-[3.1rem] p:px-[2.7rem] t:px-[3vw] rounded-[0.5rem] t:rounded-[0.5vw] py-[0.5rem] l:py-[0.4rem] p:py-[0.3rem] t:py-[0.3vw]' onClick={handleClickStory}>
              CLICK
            </div>
          </div>
        </article>
      </section>
      {/* <section className='w-[100%] pt-[10rem] bg-[#FFC82C] flexRow h-[auto] items-start p:h-[auto] l:pt-[5.5rem] p:pt-[4rem] t:pt-[6vw]'>
        <article className='max-w-[120rem] px-[2rem] w-[100%] flexCol justify-start t:px-[2vw] mx-[2rem] t:mx-[2vw]'>
          <div className='w-[100%] flexRow mb-[2rem] p:mb-[1.5rem] t:mb-[6vw]'>
            <p className='font-impact text-[7rem] text-white [-webkit-text-stroke:3px_#000000] l:[-webkit-text-stroke:2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:[-webkit-text-stroke:0.3vw_#000000] font-[800] l:text-[5.5rem] p:text-[4rem] t:text-[5vw]'>Minting will end suddenly.</p>
          </div>
          <div className='flexRow w-[100%] h-[60rem] mb-[4rem] pt-[3rem] pb-[10rem] l:h-[52rem] p:h-[100%] t:h-[100%] l:pt-[2rem] l:mb-[0rem] l:pb-[7rem] p:pt-[0rem] p:mb-[2rem] p:pb-[4rem] t:h-[auto] t:mb-[6vw] t:pt-[0vw] t:pb-[0vw]'>
            <div className='w-[100%] h-[100%] flexRow bg-[#FFC82C] text-white rounded-[0.5rem] t:rounded-[0.5vw] t:flexCol'>
              <div className='w-[60%] h-[100%] flexCol rounded-l-[0.5rem] rounded-r-[0.5rem] bg-center bg-no-repeat bg-cover t:w-[100%] t:h-[90vw] t:rounded-l-[0.5vw] t:rounded-t-[0.5vw] p:py-[2rem] p:w-[50%] bg-dark-gray border-[#FFC82C] mr-[1rem] t:mr-[0vw] t:mb-[2vw]'>
                <GachaWheel rouletteResult={rouletteResult} setRouletteResult={setRouletteResult} startMintHandler={startMintHandler} endMintHandler={endMintHandler} />
              </div>
              <div className='w-[40%] h-[100%] flexCol text-center px-[2.5rem] l:px-[2.3rem] t:w-[100%] t:px-[21vw] rounded-r-[0.5rem] rounded-l-[0.5rem] p:py-[2.4rem] p:rounded-b-[0.5rem] t:rounded-b-[0.5vw] p:w-[50%] t:h-[auto] bg-dark-gray'>
                <p className='text-[5rem] leading-[5rem] font-oswald font-[800] text-[#FFC82C] pb-[1rem] l:text-[4rem] l:leading-[4rem] l:pb-[0.8rem] p:text-[3rem] p:pt-[2rem] p:pb-[0.5rem] t:text-[7vw] t:leading-[7vw] t:pt-[6vw] text-white'>PeFUD NFT</p>
                <div className={`flexRow w-[100%] pb-[6rem] l:pb-[4rem] text-[3rem] text-white font-oswald font-[800] l:text-[2.3rem] p:text-[2rem] p:px-[0rem] p:pt-[0rem] p:pb-[2rem] t:text-[3.5vw] t:px-[0vw] t:pt-[1vw] t:pb-[7vw]`}>{rouletteResult === 'Allowed.' ? 'You can get now!' : rouletteResult === 'Not allowed.' ? 'Spin the wheel and mint an NFT!' : 'Please wait.'}</div>
                <div className='w-[100%] h-[1.7rem] flexRow justify-start bg-gray rounded-[0.5rem] relative mb-[1rem] l:mb-[0.8rem t:rounded-[0.5vw] p:mb-[2rem] t:mb-[2vw] l:h-[1.4rem] p:h-[1.2rem] t:h-[1.7vw] t:mb-[3vw]'>
                  <div className='rounded-[0.5rem] h-100% bg-[#FFC82C] font-roboto font-[800] t:rounded-[0.5vw]' style={{ width: `${Math.ceil((ableMintingNftAmount / totalSupplyNftAmount) * 100) < 1 ? 1 : Math.ceil((ableMintingNftAmount / totalSupplyNftAmount) * 100)}%` }}></div>
                </div>
                <div className='w-[100%] flexRow justify-end text-[1.8rem] text-white font-roboto font-[800] pb-[2rem] l:text-[1.7rem] l:pb-[0.8rem] p:text-[1.8rem] p:pb-[1rem] t:text-[3vw] t:pb-[2vw]'>{`${ableMintingNftAmount} / ${totalSupplyNftAmount}`}</div>

                <div className='w-[100%] pb-[3rem] l:pb-[3rem] t:pb-[3vw] p:pb-[2rem]'>
                  <p className='text-[2rem] text-white flex justify-between items-center border-t border-b py-[0.5rem] border-white font-roboto font-[800] l:text-[1.8rem] l:py-[0.4rem] p:text-[1.8rem] p:py-[1rem] t:text-[3vw] t:py-[1vw]'>
                    Total <span className='text-[2rem] text-white font-roboto font-[800] l:text-[1.8rem] p:text-[1.8rem] t:text-[3vw]'>0 ZEN + GAS</span>
                  </p>
                </div>
                <If condition={rouletteResult === 'Allowed.' || rouletteResult === 'Not allowed.'}>
                  <div className='w-[100%] relative'>
                    <button className={`w-[100%] text-white text-[3rem] leading-[3rem] py-[1rem] border-0.1rem border-white rounded-[0.5rem] font-roboto font-[800] mb-[0.5rem] t:rounded-[0.5vw] l:text-[2.6rem] l:leading-[2.6rem] flexRow l:py-[0.8rem] l:mb-[1rem] p:text-[2.5rem] p:leading-[2.5rem] p:py-[1rem] p:mb-[3rem] t:text-[4vw] t:leading-[4vw] t:h-[7vw] t:mb-[6vw] t:py-[0rem] ${rouletteResult === 'Pending.' || rouletteResult === 'Not allowed.' ? 'cursor-not-allowed' : 'cursor-pointer animate-[toggle-border-yellow_1s_infinite]'}`} disabled={rouletteResult === 'Pending.' || rouletteResult === 'Not allowed.' ? true : false} onClick={handleClickMint}>
                      Mint Now
                    </button>
                    <If condition={rouletteResult === 'Allowed.'}>
                      <div className='w-[5rem] h-[5rem] absolute right-[-5rem] top-[5rem] animate-[toggle-arrow_1s_infinite_linear] t:w-[6vw] t:h-[6vw] t:top-[5%] t:right-[-5%]'>
                        <div className='w-[100%] h-[100%] rotate-[225deg] bg-no-repeat bg-center bg-contain' style={{ backgroundImage: 'url("/images/red-arrow.png")' }}></div>
                      </div>
                    </If>
                  </div>
                </If>
                <If condition={rouletteResult === 'Pending.'}>
                  <button className={`w-[100%] text-white text-[3rem] h-[5rem] border-0.1rem border-white rounded-[0.5rem] flexRow font-roboto font-[800] mb-[0.5rem] t:rounded-[0.5vw] l:text-[2.6rem] l:h-[4.2rem] l:mb-[1rem] p:text-[2.5rem] p:h-[4.5rem] p:mb-[3rem] t:text-[5vw] t:h-[10vw] t:mb-[6vw] t:py-[0rem] ${rouletteResult === 'Pending.' || rouletteResult === 'Not allowed.' ? 'cursor-not-allowed' : 'cursor-pointer'}`} disabled={rouletteResult === 'Pending.' || rouletteResult === 'Not allowed.' ? true : false} onClick={handleClickMint}>
                    <div className='relative rounded-[50%] border-[0.5rem] border-white w-[3rem] h-[3rem] animate-spin'>
                      <div className='absolute w-[1rem] h-[1rem] bg-dark-gray top-[-0.3rem] left-[-0.3rem] rotate-[45deg]'></div>
                    </div>
                    <div className='text-white font-[800] pl-[1rem] text-[3rem] font-roboto'>Loading...</div>
                  </button>
                </If>
              </div>
            </div>
          </div>
        </article>
        <If condition={handlerModal === true}>
          <Portal handleModal={handlerModal} setHandleModal={setHandlerModal}>
            <article className='px-[4rem] py-[4rem] w-[40rem] rounded-[0.5rem] bg-[#FFFFFF] flexCol cursor-auto t:w-[70vw] t:px-[5vw] py-[5vw] t:rounded-[0.5vw] relative'>
              <div className='absolute top-[4rem] right-[4rem] w-[4rem] h-[4rem] bg-no-repeat bg-contain bg-center cursor-pointer' style={{ backgroundImage: 'url("/images/close.png")' }} onClick={() => setHandlerModal(false)}></div>
              <div className='w-[15rem] h-[15rem] bg-center bg-no-repeat mt-[5rem] mb-[2rem] flexRow text-[12rem] pt-[1rem] pl-[0.8rem] t:w-[18vw] t:h-[18vw] t:mt-[1vw] t:mb-[2vw] t:text-[15vw] t:pl-[2.5vw]'>⏳</div>
              <div className='font-[roboto] font-[800] text-3rem t:text-[4.5vw]'></div>
              <div className='font-[roboto] font-[300] text-[1.5rem] t:text-[2.3vw] text-center'>It takes some time to record the minting information on the block. Please wait a moment,</div>
              <div className='font-[roboto] font-[300] text-[1.5rem] pb-[4rem] text-red t:text-[2.3vw] t:pb-[5.6vw] text-center font-[500]'>no longer than 1 minute.</div>
              <div className='w-[100%] h-[5rem] cursor-pointer text-white bg-blue font-[roboto] font-[800] flexRow text-[2.5rem] rounded-[0.5rem] t:h-[7.2vw] t:text-[3.6vw] t:rounded-[0.5vw]' onClick={handleClickConfirmMinting}>
                Confirm
              </div>
            </article>
          </Portal>
        </If>
      </section> */}
      <If condition={walletAddress === '' || localStorage.getItem('accessToken') === null || localStorage.getItem('refreshToken') === null}>
        <section className='w-[100%] flexCol'>
          <article className='max-w-[120rem] w-[100%] pl-[2rem] l:px-[2rem] p:px-[2rem] t:px-[2rem]'>
            <div className='w-[100%] font-roboto text-[3rem] font-[800] my-[5rem] flexRow l:text-[2.5rem] l:my-[4rem] p:text-[2rem] p:my-[3rem] t:text-[3vw] t:my-[4vw]'>If you want to watch your NFTs, please connect wallet.</div>
          </article>
        </section>
      </If>
      <If condition={walletAddress !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('refreshToken') !== null}>
        <section className='w-[100%] flexCol'>
          <article className='max-w-[120rem] w-[100%] pl-[2rem] l:px-[2rem] p:px-[2rem] t:px-[2rem]'>
            <div className='w-[100%] font-roboto text-[5rem] font-[800] my-[5rem] flexRow l:text-[4rem] l:my-[4rem] p:text-[3rem] p:my-[3rem] t:text-[4vw] t:my-[4vw]'>YOUR NFT</div>
            <If condition={nftList.length === 0 && basicStakingNftList.length === 0}>
              <div className='w-[100%] flexRow'>
                <div className='w-[500px] p-[40px] bg-[#FFF] h-[500px] rounded-[8px] flexCol l:w-[400px] l:h-[400px] l:p-[35px] p:w-[350px] p:h-[350px] p:p-[30px] t:w-[50vw] t:h-[50vw] t:p-[3vw] box-shadow-m'>
                  <div className='bg-contain-center w-[200px] h-[200px] mb-[40px] l:w-[180px] l:h-[180px] l:mb-[35px] p:w-[150px] p:h-[150px] p:mb-[30px] t:w-[30vw] t:h-[30vw] t:mb-[4vw]' style={{ backgroundImage: 'url(/images/kuswap.png)' }}></div>
                  <div className='font-roboto font-[600] text-[16px] t:text-[1.3vw] mb-[3vw]'>You don't have any PeFUD NFT. You can get that at Ku-Swap.</div>
                  <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2rem] px-[3rem] py-[1rem] cursor-pointer l:text-[1.8rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.5rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[1.8vw] t:px-[3vw] t:py-[1vw]'>Go to Ku-Swap</div>
                </div>
              </div>
            </If>
            <NftList nftList={nftList} setNftList={setNftList} setBasicStakingNftList={setBasicStakingNftList} handleReveal={handleReveal} />
          </article>
        </section>
      </If>
      <If condition={basicStakingNftList.length !== 0 && walletAddress !== '' && localStorage.getItem('accessToken') !== null && localStorage.getItem('refreshToken') !== null}>
        <section className='w-[100%] flexCol bg-cover-center' style={{ backgroundImage: 'url("/images/stacking-bg.png")' }}>
          <article className='max-w-[120rem] w-[100%] pl-[2rem] l:px-[2rem] p:px-[2rem] t:px-[2rem]'>
            <StakingNftList setNftList={setNftList} stakingNftList={basicStakingNftList} setStakingNftList={setBasicStakingNftList} />
          </article>
        </section>
      </If>
      <section className='w-[100%] flexRow bg-dark-gray t:h-[35vw]'>
        <article className='max-w-[120rem] flexCol w-[100%] h-[45rem] pb-[6.5rem] l:hidden p:hidden t:hidden'>
          <ReactTyped strings={['HOW TO MINT?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '10rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '2px #fff', letterSpacing: '-0.3rem', height: '10rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-[100%] h-[25rem] pb-[6rem] l:flexCol'>
          <ReactTyped strings={['HOW TO MINT?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '8rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '2px #fff', letterSpacing: '-0.3rem', height: '8rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-[100%] h-[20rem] pb-[3.5rem] p:flexCol'>
          <ReactTyped strings={['HOW TO MINT?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '5rem', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '1px #fff', letterSpacing: '-0.3rem', height: '5rem' }} />
        </article>
        <article className='max-w-[120rem] hidden w-[100%] t:flexCol t:pb-[7vw]'>
          <ReactTyped strings={['HOW TO MINT?']} typeSpeed={50} backSpeed={50} backDelay={1500} showCursor={false} loop={true} style={{ fontSize: '9vw', color: '#ec3b23', fontFamily: 'Oswald', fontWeight: '800', WebkitTextStroke: '1px #fff', letterSpacing: '-0.3rem', paddingTop: '0vw', paddingBottom: '0vw', height: '9vw' }} />
        </article>
      </section>
      <section className='w-[100%] flexRow'>
        <article className='max-w-[120rem] flexRow w-[100%] justify-between pt-[12rem] pb-[8rem] px-[2rem] l:pt-[6rem] l:pb-[4rem] p:pt-[5rem] p:pb-[3rem] t:pt-[6vw] t:flex-wrap t:pb-[0vw] t:px-[4vw] '>
          <div className='flexCol w-[28rem] h-[28rem] bg-dark-gray rounded-[0.5rem] justify-start p-[2rem] l:w-[24%] l:h-[22rem] p:w-[24%] p:h-[16rem] p:px-[1.5rem] p:py-[1.5rem] t:w-[48%] t:h-[32vw] t:mb-[4vw] t:p-[3vw]'>
            <div className='text-red font-roboto font-[600] text-[2.3rem] w-[100%] pb-[2rem] l:text-[1.8rem] l:pb-[1rem] p:text-[1.3rem] p:pb-[0.8rem] t:text-[2.7vw] t:pb-[1.7vw] flexRow'>
              <span className='text-red font-roboto font-[600] text-[3vw] pr-[1vw] hidden t:inline'>1.</span>
              WALLET CONNECTION
            </div>
            <div className='text-white font-roboto font-[600] text-[1.6rem] w-[100%] l:text-[1.3rem] p:text-[1rem] t:text-[2.2vw]'>
              Connect your wallet and prepare BONES for minting. If you don't have BONES, you can acquire them through donations on the page below.
              <div className='pt-[2.5rem] font-roboto text-[1.6rem] leading-[1.6rem] text-white font-[600] w-100% flexRow text-[orange] cursor-pointer l:pt-[2rem] l:text-[1.3rem] l:leading-[1.3rem] p:text-[1rem] p:leading-[1rem] p:pt-[1rem] t:text-[2.2vw] t:leading-[3vw] t:pt-[2.5vw]' onClick={handleClickNavigateDonation}>
                CLICK
              </div>
            </div>
          </div>
          <div className='flexCol w-[28rem] h-[28rem] bg-dark-gray rounded-[0.5rem] justify-start p-[2rem] l:w-[24%] l:h-[22rem] p:w-[24%] p:h-[16rem] p:px-[1.5rem] p:py-[1.5rem] t:w-[48%] t:h-[32vw] t:mb-[4vw] t:p-[3vw]'>
            <div className='text-red font-roboto font-[600] text-[2.3rem] w-[100%] pb-[2rem] l:text-[1.8rem] l:pb-[1rem] p:text-[1.3rem] p:pb-[0.8rem] t:text-[2.7vw] t:pb-[1.7vw] flexRow'>
              <span className='text-red font-roboto font-[600] text-[3vw] pr-[1vw] hidden t:inline'>2.</span>
              BONES BURN
            </div>
            <div className='text-white font-roboto font-[600] text-[1.6rem] w-[100%] l:text-[1.3rem] p:text-[1rem] t:text-[2.2vw]'>Burn BONES to increase your chances of minting an NFT. You can set the probability up to 99%.</div>
          </div>
          <div className='flexCol w-[28rem] h-[28rem] bg-dark-gray rounded-[0.5rem] justify-start p-[2rem] l:w-[24%] l:h-[22rem] p:w-[24%] p:h-[16rem] p:px-[1.5rem] p:py-[1.5rem] t:w-[48%] t:h-[32vw] t:mb-[4vw] t:p-[3vw]'>
            <div className='text-red font-roboto font-[600] text-[2.3rem] w-[100%] pb-[2rem] l:text-[1.8rem] l:pb-[1rem] p:text-[1.3rem] p:pb-[0.8rem] t:text-[2.7vw] t:pb-[1.7vw] flexRow'>
              <span className='text-red font-roboto font-[600] text-[3vw] pr-[1vw] hidden t:inline'>3.</span>
              SPIN & STOP
            </div>
            <div className='text-white font-roboto font-[600] text-[1.6rem] w-[100%] l:text-[1.3rem] p:text-[1rem] t:text-[2.2vw] mb-[1.2rem] l:mb-[1rem] p:mb-[0.6rem] t:mb-[1.5vw]'>Once you've decided on the probability, press Spin to burn BONES and challenge for an NFT by pressing Stop. Good luck!</div>
          </div>
          <div className='flexCol w-[28rem] h-[28rem] bg-dark-gray rounded-[0.5rem] justify-start p-[2rem] l:w-[24%] l:h-[22rem] p:w-[24%] p:h-[16rem] p:px-[1.5rem] p:py-[1.5rem] t:w-[48%] t:h-[32vw] t:mb-[4vw] t:p-[3vw]'>
            <div className='text-red font-roboto font-[600] text-[2.3rem] w-[100%] pb-[2rem] l:text-[1.8rem] l:pb-[1rem] p:text-[1.3rem] p:pb-[0.8rem] t:text-[2.7vw] t:pb-[1.7vw] flexRow'>
              <span className='text-red font-roboto font-[600] text-[3vw] pr-[1vw] hidden t:inline'>4.</span>
              GET NFT
            </div>
            <div className='text-white font-roboto font-[600] text-[1.6rem] w-[100%] l:text-[1.3rem] p:text-[1rem] t:text-[2.2vw]'>If you successfully draw an NFT, press the MINT NOW button to mint your NFT. You can mint one NFT at a time. Afterwards, check your NFT below.</div>
          </div>
        </article>
      </section>
      <section className='w-[100%] flexRow'>
        <article className='max-w-[120rem] flexCol w-[100%]'>
          <h2 className='text-[10rem] font-oswald font-[800] pb-[8rem] l:pb-[4rem] l:text-[8rem] p:pb-[3rem] p:text-[6rem] t:text-[8vw] t:pb-[2vw] text-[#ec3b23] [-webkit-text-stroke:3px_#ffffff] l:[-webkit-text-stroke:2px_#ffffff] p:[-webkit-text-stroke:1px_#ffffff] t:[-webkit-text-stroke:0.3vw_#ffffff]'>NFT ROADMAP</h2>
          <div className='w-[100%]'>
            <div className='w-100% px-[2rem] pb-[8rem] l:pb-[6rem] p:pb-[4rem] t:px-[2vw] t:pb-[4vw]'>
              {/* <div className='w-[100%] h-[4rem] relative'>
                <div className='font-roboto font-[800] text-[3rem] top-[50%] translate-y-[-50%] absolute left-[10%]'>MINT</div>
                <div className='font-roboto font-[800] text-[3rem] top-[50%] translate-y-[-50%] absolute left-[35%]'>REVEAL</div>
                <div className='font-roboto font-[800] text-[3rem] top-[50%] translate-y-[-50%] absolute right-[35%]'>GAME</div>
                <div className='font-roboto font-[800] text-[3rem] top-[50%] translate-y-[-50%] absolute right-[10%]'>????</div>
              </div> */}
              <div className='w-[100%] h-[0.7rem] rounded-[0.7rem] relative mt-[24rem] flexRow justify-between px-[10rem] l:px-[9rem] l:mt-[21rem] p:px-[10rem] p:mt-[18rem] p:h-[0.5rem] t:h-[1vw] t:px-[12vw] t:mt-[27vw] t:rounded-[1vw]'>
                <div className='absolute w-[100%] bg-dark-gray h-[100%] left-[0%] rounded-[0.7rem] top-[-1.5rem] p:top-[-1.25rem] t:rounded-[1vw] t:top-[-2vw]'></div>
                <div className='w-[3rem] h-[3rem] rounded-[50%] top-[50%] translate-y-[-50%] border-[0.7rem] bg-[#FFC82C] p:border-[0.5rem] p:w-[2.5rem] p:h-[2.5rem] t:w-[4vw] t:h-[4vw] t:border-[1vw]'>
                  <div className='w-[100%] h-[100%] relative'>
                    <div className='absolute left-[50%] translate-x-[-50%] top-[-24rem] flexCol w-[16rem] h-[24rem] pb-[1rem] pt-[2rem] flexCol justify-between l:w-[13rem] l:h-[21rem] l:top-[-22rem] p:w-[10rem] p:h-[17rem] p:top-[-17rem] t:top-[-22vw] t:w-[13vw] t:h-[20vw] t:pb-[0vw] t:pt-[0vw]'>
                      <div className='w-[16rem] h-[16rem] bg-no-repeat bg-center bg-cover rounded-[0.5rem] l:w-[13rem] l:h-[13rem] p:w-[10rem] p:h-[10rem] t:w-[13vw] h-[13vw] t:rounded-[0.5vw]' style={{ backgroundImage: 'url("/images/roadmap.png")' }}>
                        <div className='w-[100%] h-[100%] opacity-[0.5] bg-[#000]'></div>
                      </div>
                      <div className='font-roboto font-[800] text-[2.5rem] l:text-[2.2rem] p:text-[1.9rem] t:text-[2.5vw] relative'>
                        MINT
                        <div className='absolute w-[100%] h-[0.3rem] t:h-[0.3vw] bg-black top-[50%] translate-y-[-50%]'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-[3rem] h-[3rem] rounded-[50%] top-[50%] translate-y-[-50%] border-[0.7rem] bg-red p:border-[0.5rem] p:w-[2.5rem] p:h-[2.5rem] t:w-[4vw] t:h-[4vw] t:border-[1vw]'>
                  <div className='w-[100%] h-[100%] relative'>
                    <div className='absolute left-[50%] translate-x-[-50%] top-[-24rem] flexCol w-[16rem] h-[24rem] pb-[1rem] pt-[2rem] flexCol justify-between l:w-[13rem] l:h-[21rem] l:top-[-22rem] p:w-[10rem] p:h-[17rem] p:top-[-17rem] t:top-[-22vw] t:w-[13vw] t:h-[20vw] t:pb-[0vw] t:pt-[0vw]'>
                      <div className='w-[16rem] h-[16rem] bg-no-repeat bg-center bg-cover rounded-[0.5rem] l:w-[13rem] l:h-[13rem] p:w-[10rem] p:h-[10rem] t:w-[13vw] h-[13vw] t:rounded-[0.5vw] animate-[shake-roadmap_1s_infinite_linear]' style={{ backgroundImage: 'url("/images/1.png")' }}></div>
                      <div className='font-roboto font-[800] text-[2.5rem] l:text-[2.2rem] p:text-[1.9rem] t:text-[2.5vw] text-red'>REVEAL</div>
                    </div>
                  </div>
                </div>
                <div className='w-[3rem] h-[3rem] rounded-[50%] top-[50%] translate-y-[-50%] border-[0.7rem] bg-[#FFC82C] p:border-[0.5rem] p:w-[2.5rem] p:h-[2.5rem] t:w-[4vw] t:h-[4vw] t:border-[1vw]'>
                  <div className='w-[100%] h-[100%] relative'>
                    <div className='absolute left-[50%] translate-x-[-50%] top-[-24rem] flexCol w-[16rem] h-[24rem] pb-[1rem] pt-[2rem] flexCol justify-between l:w-[13rem] l:h-[21rem] l:top-[-22rem] p:w-[10rem] p:h-[17rem] p:top-[-17rem] t:top-[-22vw] t:w-[13vw] t:h-[20vw] t:pb-[0vw] t:pt-[0vw]'>
                      <div className='w-[16rem] h-[16rem] bg-no-repeat bg-center bg-cover rounded-[0.5rem] l:w-[13rem] l:h-[13rem] p:w-[10rem] p:h-[10rem] t:w-[13vw] h-[13vw] t:rounded-[0.5vw]'></div>
                      <div className='font-roboto font-[800] text-[2.5rem] l:text-[2.2rem] p:text-[1.9rem] t:text-[2.5vw]'>GAME</div>
                    </div>
                  </div>
                </div>
                <div className='w-[3rem] h-[3rem] rounded-[50%] top-[50%] translate-y-[-50%] border-[0.7rem] bg-[#FFC82C] p:border-[0.5rem] p:w-[2.5rem] p:h-[2.5rem] t:w-[4vw] t:h-[4vw] t:border-[1vw]'>
                  <div className='w-[100%] h-[100%] relative'>
                    <div className='absolute left-[50%] translate-x-[-50%] top-[-24rem] flexCol w-[16rem] h-[24rem] pb-[1rem] pt-[2rem] flexCol justify-between l:w-[13rem] l:h-[21rem] l:top-[-22rem] p:w-[10rem] p:h-[17rem] p:top-[-17rem] t:top-[-22vw] t:w-[13vw] t:h-[20vw] t:pb-[0vw] t:pt-[0vw]'>
                      <div className='w-[16rem] h-[16rem] bg-no-repeat bg-center bg-cover rounded-[0.5rem] l:w-[13rem] l:h-[13rem] p:w-[10rem] p:h-[10rem] t:w-[13vw] h-[13vw] t:rounded-[0.5vw]'></div>
                      <div className='font-roboto font-[800] text-[2.5rem] l:text-[2.2rem] p:text-[1.9rem] t:text-[2.5vw] relative'>
                        ????
                        {/* <div className='absolute w-[100%] h-[0.3rem] bg-black top-[50%] translate-y-[-50%]'></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <Footer backgroundColor='#FFC82C'>
        <div className='flexCol w-[100%]'>
          <div className='w-13% pb-7% bg-contain bg-no-repeat bg-left mt-[7%]' style={{ backgroundImage: 'url("/images/logo-pefud.png")' }}></div>
          <div className='text-1.6rem mb-2% l:text-1.5rem p:text-1.3rem t:text-2vw font-roboto font-[800] pb-[4rem] l:pb-[3rem] p:pb-[2.8rem] t:pb-[6vw]'>CopyRight(C) NEVERED. All rights reserved.</div>
        </div>
      </Footer>
      {/* <Container>
        <Section></Section>
      </Container> */}
    </>
  );
};

export default Mint;
