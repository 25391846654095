import { useEffect, useState } from 'react';
import { NFT, StakingNFT } from '../pages/Mint';
import moment from 'moment-timezone';
import If from './If';
import Portal from './Portal';
import axios from 'axios';
import { basicStakingContractAddress, dev, getBasicStakingNfts, getNFTs } from '../services';
import { ethers } from 'ethers';
import { basicStakingContractABI } from '../utils/basicStakingContractABI';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../stores/store';
import { setBonesPoint, setChainId, setPermissionAddresses, setWalletAddress } from '../stores/walletSlice';

type StakingNftItemProps = {
  id: string;
  imageUrl: string;
  stakingTimestamp: string;
  setNftList: React.Dispatch<React.SetStateAction<NFT[]>>;
  setStakingNftList: React.Dispatch<React.SetStateAction<StakingNFT[]>>;
};

const StakingNftItem: React.FC<StakingNftItemProps> = ({ id, imageUrl, stakingTimestamp, setNftList, setStakingNftList }: StakingNftItemProps): JSX.Element => {
  const [handlerMouseEnter, setHandlerMouseEnter] = useState(false);
  const [handlerPortal, setHandlerPortal] = useState(false);
  const [passedTime, setPassedTime] = useState('');

  const { chainId, walletAddress, permissionAddresses, bonesPoint } = useSelector((state: RootState) => state.wallet);
  const dispatch: AppDispatch = useDispatch();

  const [handlerWaitingUnstake, setHandlerWaitingUnstake] = useState(false);

  const init = () => {
    dispatch(setWalletAddress(''));
    dispatch(setPermissionAddresses([]));
    dispatch(setBonesPoint('0'));
    dispatch(setChainId(-1));
  };
  const handleMouseEnter = () => {
    setHandlerMouseEnter(true);
  };
  const handleMouseLeave = () => {
    setHandlerMouseEnter(false);
  };
  const handleClickStakingNftItem = () => {
    setHandlerPortal(true);
  };

  const handleClickClaim = () => {};

  const handleClickUnStaking = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    const accessToken = localStorage.getItem('accessToken') ?? '';
    const refreshToken = localStorage.getItem('refreshToken') ?? '';

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const basicStakingContract = new ethers.Contract(basicStakingContractAddress, basicStakingContractABI, signer);

    if (parseInt((await signer.provider.getNetwork()).chainId.toString()) !== 7332) {
      return;
    }

    await axios
      .post(dev + '/api/donation/bones_amount', { walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken } })
      .then(async () => {
        await basicStakingContract
          .unstakeNFT(id)
          .then(async (tx) => {
            setHandlerWaitingUnstake(true);
            await axios.post(dev + '/api/staking/unstake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}` } }).catch(async (err) => {
              if (err.response.data.message === 'Generate Token.') {
                const accessToken = err.response.data.accessToken;
                const refreshToken = err.response.data.refreshToken;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                await axios.post(dev + '/api/staking/unstake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}` } });
              }
            });

            return tx.wait();
          })
          .then(async (receipt) => {
            setHandlerWaitingUnstake(false);
            if (receipt.status === 1) {
              const initNFTs = async () => {
                const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

                setNftList(nftList);
              };

              initNFTs();

              const initStakingNFTs = async () => {
                await getBasicStakingNfts(walletAddress).then((res) => {
                  if (res === undefined) return;
                  else setStakingNftList(res);
                });
              };

              initStakingNFTs();
            } else {
              await axios.post(dev + 'api/staking/unstake-resent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } }).catch(async (err) => {
                if (err.response.data.message === 'Generate Token.') {
                  const accessToken = err.response.data.accessToken;
                  const refreshToken = err.response.data.refreshToken;

                  localStorage.setItem('accessToken', accessToken);
                  localStorage.setItem('refreshToken', refreshToken);

                  await axios.post(dev + 'api/staking/unstake-resent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } });
                }
              });
            }
          })
          .catch(async (err) => {
            console.log(err);
          });
      })
      .catch(async (err) => {
        if (err.response.data.message === 'Generate Token.') {
          const accessToken = err.response.data.accessToken;
          const refreshToken = err.response.data.refreshToken;

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          await basicStakingContract
            .unstakeNFT(id)
            .then(async (tx) => {
              setHandlerWaitingUnstake(true);
              await axios.post(dev + '/api/staking/unstake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}` } }).catch(async (err) => {
                if (err.response.data.message === 'Generate Token.') {
                  const accessToken = err.response.data.accessToken;
                  const refreshToken = err.response.data.refreshToken;

                  localStorage.setItem('accessToken', accessToken);
                  localStorage.setItem('refreshToken', refreshToken);

                  await axios.post(dev + 'api/staking/unstake', { nftTokenId: id.toString(), walletAddress: walletAddress.toLocaleLowerCase() }, { headers: { Authorization: `Bearer ${accessToken}` } });
                }
              });

              return tx.wait();
            })
            .then(async (receipt) => {
              setHandlerWaitingUnstake(false);
              if (receipt.status === 1) {
                const initNFTs = async () => {
                  const nftList = (await getNFTs(walletAddress).catch((err) => console.log(err))) ?? [];

                  setNftList(nftList);
                };

                initNFTs();

                const initStakingNFTs = async () => {
                  await getBasicStakingNfts(walletAddress).then((res) => {
                    if (res === undefined) return;
                    else setStakingNftList(res);
                  });
                };

                initStakingNFTs();
              } else {
                await axios.post(dev + 'api/staking/unstake-resent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } }).catch(async (err) => {
                  if (err.response.data.message === 'Generate Token.') {
                    const accessToken = err.response.data.accessToken;
                    const refreshToken = err.response.data.refreshToken;

                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('refreshToken', refreshToken);

                    await axios.post(dev + 'api/staking/unstake-resent', { nftTokenId: id.toString() }, { headers: { Authorization: `Bearer ${accessToken}` } });
                  }
                });
                return;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) init();
          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
        }
      });
  };

  useEffect(() => {
    const stakingStartTime = moment.tz(stakingTimestamp, 'UTC');

    const intervalId = setInterval(() => {
      const currentTime = moment.tz(moment(), 'UTC');

      const duration = moment.duration(currentTime.diff(stakingStartTime));

      const printStrTime = (time: number) => {
        return time < 10 ? '0' + time : time;
      };

      const formattedTimePassed = `${printStrTime(duration.days() * 24 + duration.hours())}:${printStrTime(duration.minutes())}:${printStrTime(duration.seconds())}:${printStrTime(Math.floor(duration.milliseconds() / 10))}`;

      setPassedTime(formattedTimePassed);
    }, 10);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className='relative w-[27.5rem] h-[35.5rem] rounded-[0.5rem] bg-white flexCol mr-[2rem] mb-[2rem] p-[2rem] justify-start l:w-[23.5%] l:h-[auto] l:mr-[2%] l:mb-[2%] l:p-[2%] nth-child-4n-mr-0 p:w-[23.5%] p:h-[auto] p:mr-[2%] t:w-[23.5%] t:h-[auto] t:mr-[2%] cursor-pointer t:p-[2vw] t:mb-[2vw]' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClickStakingNftItem}>
        <div className='w-[23.5rem] h-[23.5rem] rounded-[0.5rem] bg-[#ddd] mb-[2rem] l:mb-[1.5rem] p:mb-[1rem] t:mb-[1vw] bg-contain bg-center bg-no-repeat relative l:w-[100%] l:h-[0rem] l:pb-[100%] p:w-[100%] p:h-[0rem] p:pb-[100%] t:w-[100%] t:h-[0rem] t:pb-[100%]'>
          <img src={imageUrl} alt='' className='bg-contain-center w-100% h-100% absolute border left-[0px] top-[0px] animate-[bounce-staking-nft_0.2s_infinite]' />
        </div>
        <div className='w-[100%] flexCol'>
          <div className='font-roboto flexRow w-[100%] font-roboto font-[600] text-[2.8rem] l:text-[2.4rem] p:text-[2.1rem] t:text-[2.3vw] t:leading-[2.3vw] t:mt-[0.5vw]'>No. {id}</div>
          <div className='w-[100%] flexRow'>
            <div className='inline-block w-[auto] font-roboto font-[800] text-[3rem] mt-[0.5rem] l:text-[2.5rem] p:text-[2rem] t:text-[2.35vw] t:leading-[2.4vw]'>
              {passedTime
                .split('')
                .filter((v, i, a) => (a.length - i + 5 >= i ? v : ''))
                .join('')}
            </div>
            <div className='inline-block w-[auto] font-roboto font-[800] text-[3rem] mt-[0.5rem] l:text-[2.5rem] p:text-[2rem] t:text-[2.35vw] t:leading-[2.4vw]'>
              {passedTime
                .split('')
                .filter((v, i, a) => (a.length - i + 5 < i ? v : ''))
                .join('')}
            </div>
          </div>
        </div>
        <If condition={handlerMouseEnter === true}>
          <div className='absolute w-[100%] h-[100%] backdrop-blur-sm top-[0px] left-[0px] flexRow'>
            <If condition={handlerWaitingUnstake === false}>
              <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[2vw] t:px-[3vw] t:py-[1vw]' onClick={handleClickUnStaking}>
                UNSTAKING
              </div>
            </If>
          </div>
        </If>
        <If condition={handlerWaitingUnstake === true}>
          <div className='w-[100%] h-[100%] absolute left-[0%] top-[0%] flexRow'>
            <div className='bg-contain-center animate-[rotate-record_1s_infinite_linear] w-[50%] h-[50%]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
          </div>
        </If>
      </div>
      <If condition={handlerPortal === true}>
        <Portal handleModal={handlerPortal} setHandleModal={setHandlerPortal}>
          <article className='px-[4rem] py-[4rem] w-[70rem] rounded-[0.5rem] overflow-hidden bg-[#FFFFFF] flexCol cursor-auto t:w-[70vw] t:px-[5vw] t:rounded-[0.5vw] relative l:w-[60rem] p:w-[50rem]'>
            <div className='bg-no-repeat w-[100%] pb-[100%] bg-center bg-cover border rounded-[0.5rem] t:rounded-[0.5vw] relative' style={{ backgroundImage: `url("${imageUrl}")` }}>
              <div className='absolute left-[0px] top-[0px] w-[100%] h-[100%] flexRow'>
                <If condition={handlerWaitingUnstake === false}>
                  <div className='font-roboto font-[800] text-white rounded-[0.5rem] bg-[#0B8CE9] text-[2.5rem] px-[3rem] py-[1rem] cursor-pointer l:text-[2.2rem] l:px-[2.5rem] l:py-[0.8rem] p:text-[1.6rem] p:px-[2rem] p:py-[0.5rem] t:rounded-[0.5vw] t:text-[4vw] t:px-[6vw] t:py-[2vw]' onClick={handleClickUnStaking}>
                    UNSTAKING
                  </div>
                </If>
                <If condition={handlerWaitingUnstake === true}>
                  <div className='w-[100%] h-[100%] absolute left-[0%] top-[0%] flexRow'>
                    <div className='bg-contain-center animate-[rotate-record_1s_infinite_linear] w-[50%] h-[50%]' style={{ backgroundImage: 'url(/images/loading.png)' }}></div>
                  </div>
                </If>
              </div>
            </div>
          </article>
        </Portal>
      </If>
    </>
  );
};

export default StakingNftItem;
