import React, { ReactNode } from 'react';

interface Props {
  condition: boolean;
  children: ReactNode;
}

const If: React.FC<Props> = ({ condition, children }) => {
  return condition ? <>{children}</> : <></>;
};

export default If;
