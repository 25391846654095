import axios from 'axios';
import { ethers } from 'ethers';
import { NFT, StakingNFT } from '../pages/Mint';
import { contractABI } from '../utils/contractABI';
import { basicStakingContractABI } from '../utils/basicStakingContractABI';

export const dev = process.env.REACT_APP_DEV || '';
export const test = process.env.REACT_APP_TEST || '';
export const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS || '';
export const basicStakingContractAddress = process.env.REACT_APP_BASIC_STAKING_CONTRACT_ADDRESS || '';

declare global {
  interface Window {
    ethereum?: any;
  }
}

type RequestPermissionsResponse = { permissionAddresses: string[]; walletAddress: string; chainId: number; provider: ethers.BrowserProvider | null };

type RequestAuthMessage = { message: string; nonce: string };

export const eonChainNetWorkInfo = { chainId: '0x1CA4', chainName: 'Eon', nativeCurrency: { name: 'Horizen', symbol: 'ZEN', decimals: 18 }, rpcUrls: ['https://eon-rpc.horizenlabs.io/ethv1'], blockExplorerUrls: ['https://explorer.horizen.io/'] };

export const checkInstalledMetaMask = (): boolean => {
  return window.ethereum ? true : false;
};

export const requestPermissionAddresses = async (): Promise<RequestPermissionsResponse> => {
  try {
    const permissions = await window.ethereum.request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => {
      console.log(err);
    });

    if (permissions.length === 0) return { permissionAddresses: [], walletAddress: '', chainId: -1, provider: null };

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();

    const chainId = parseInt((await signer.provider.getNetwork()).chainId.toString());

    return { permissionAddresses: permissions[0].caveats[0].value ?? [], walletAddress: address ?? '', chainId, provider };
  } catch (error: any) {
    if (error.code === 4001) console.log('error');
    if (error.code === 'ACTION_REJECTED') console.log('error');

    return { permissionAddresses: [], walletAddress: '', chainId: -1, provider: null };
  }
};

export const requestBonePoints = async (walletAddress: string, accessToken: string, refreshToken: string) => {
  if (walletAddress === '') return '0';

  const result = await axios.post(
    dev + '/api/donation/bones_amount',
    { walletAddress },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Refresh-Token': refreshToken
      }
    }
  );

  return result.data.bonesAmount;
};

export const requestAuthMessage = async (walletAddress: string): Promise<RequestAuthMessage> => {
  try {
    const result = await axios.post(dev + '/api/auth/request-message', { walletAddress });

    return result.data;
  } catch (err) {
    console.log(err);
  }
  return { message: '', nonce: '' };
};

export const requestAuthLogin = async (walletAddress: string, signature: string, nonce: string) => {
  try {
    const result = await axios.post(dev + '/api/auth/login', { walletAddress, signature, nonce });

    return result.data;
  } catch (err) {
    console.log(err);
  }
  return {};
};

export const generateSIWEMessage = (domain: string, address: string, message: string) => {
  const issuedAt = new Date().toISOString();
  return `${domain} wants you to sign in with your account:
${address}

URI: https://${domain}
Version: 1
Chain ID: 1
Message: ${message}
Issued At: ${issuedAt}`;
};

export const requestDonate = async (walletAddress: string, zenAmount: string, txHash: string, accessToken: string, refreshToken: string) => {
  try {
    const result = await axios.post(
      dev + '/api/donation/donation',
      { walletAddress, zenAmount, txHash },
      {
        headers: { Authorization: `Bearer ${accessToken}`, 'X-Refresh-Token': refreshToken }
      }
    );

    return result.data.bonesAmount;
  } catch (err) {
    console.log(err);
  }
};

export const requestDonatedZenAmount = async () => {
  try {
    const result = await axios.get(dev + '/api/donation/total_donation_zen');

    return result.data.totalZenAmount;
  } catch (err) {
    console.log(err);
  }
};

export const requestMint = async () => {
  try {
    const result = await axios.post(dev + '/api/mint/mint');

    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const getNFTs = async (address: string) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const chainId = parseInt('' + (await provider.getNetwork()).chainId);

  if (chainId !== 7332) return;

  const signer = await provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);
  const tokenIdArr = await contract.walletOfOwner(address);

  const result = (
    await Promise.all(
      tokenIdArr.map(async (tokenId: number) => {
        const approved = (await contract.getApproved(tokenId)) === basicStakingContractAddress;
        const tokenURI = await contract.tokenURI(tokenId);
        const metadata = await axios.get(tokenURI);

        return { id: Number(tokenId), imageUrl: metadata.data.image, revealed: tokenURI === 'https://pefud.s3.ap-northeast-1.amazonaws.com/1.json' ? false : true, approved };
      })
    )
  ).sort((a, b) => a.id - b.id);

  return result;
};

export const getBasicStakingNfts = async (address: string) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const chainId = parseInt('' + (await provider.getNetwork()).chainId);

  if (chainId !== 7332) return;

  const signer = await provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);
  const basicStakingContract = new ethers.Contract(basicStakingContractAddress, basicStakingContractABI, signer);

  const basicStakingIdList = (await basicStakingContract.getUserStakedNFTs(address)) ?? [];

  const basicStakingNftList = await Promise.all(
    basicStakingIdList.map(async (tokenId: any) => {
      const tokenURI = await contract.tokenURI(Number(tokenId));

      const result = await axios
        .get(dev + '/api/staking/info/' + Number(tokenId))
        .then(async (res) => {
          const stakingInfo = res.data;

          const metadata: { data: { image: string } } = await axios.get(tokenURI);

          return { nftTokenId: stakingInfo.nftTokenId, imageUrl: metadata.data.image, stakingTimestamp: stakingInfo.stakingTimestamp, bonesClaimable: stakingInfo.bonesClaimable, bonesClaimed: stakingInfo.bonesClaimed };
        })
        .catch(async (err) => {
          const metadata: { data: { image: string } } = await axios.get(tokenURI);
          return { nftTokenId: tokenId, imageUrl: metadata.data.image, stakingTimestamp: '', bonesClaimable: '', bonesClaimed: '' };
        });

      return result;
    })
  );

  return basicStakingNftList as StakingNFT[];
};
