import { useEffect, useState } from 'react';
import If from './If';
import NftItem from './NftItem';
import { NFT, StakingNFT } from '../pages/Mint';

type NftListPropsType = {
  nftList: NFT[];
  setNftList: React.Dispatch<React.SetStateAction<NFT[]>>;
  setBasicStakingNftList: React.Dispatch<React.SetStateAction<StakingNFT[]>>;
  handleReveal: boolean;
};

const NftList = ({ nftList, setNftList, setBasicStakingNftList, handleReveal }: NftListPropsType) => {
  return (
    <>
      <div className='w-[100%] flex flex-wrap pb-[10rem] l:flexRow l:justify-start l:pb-[6rem] p:pb-[4rem] t:pb-[4vw]'>
        {nftList.map((nft, i) => (
          <NftItem key={nft.id + '' + i + nft.imageUrl} id={nft.id} imageUrl={nft.imageUrl} revealed={nft.revealed} approved={nft.approved} setNftList={setNftList} setBasicStakingNftList={setBasicStakingNftList} handleReveal={handleReveal} />
        ))}
      </div>
    </>
  );
};

export default NftList;
