import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from 'react-typed';

import '../scss/mobile-history.scss';

const History = () => {
  const [hiddenHandle, setHiddenHandle] = useState(false);
  const [timerHandle, setTimerHandle] = useState(70);
  const sectionRef = useRef<HTMLTableSectionElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const startHistoryRef = useRef<HTMLDivElement | null>(null);

  const [showSection, setShowSection] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const navigate = useNavigate();

  const [orientation, setOrientation] = useState<string>('');

  const updateOrientation = () => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    setOrientation(isLandscape ? 'landscape' : 'portrait');
  };

  useEffect(() => {
    updateOrientation();

    window.addEventListener('orientationchange', updateOrientation);
    window.addEventListener('resize', updateOrientation);

    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
      window.removeEventListener('resize', updateOrientation);
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = '#000';
    }
    const timer = setInterval(() => {
      setTimerHandle((prev) => (prev + 1 >= 74 ? 70 : prev + 1));
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHiddenHandle(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  const [isElementVisible, setIsElementVisible] = useState(true);
  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.backgroundColor = 'black';
      body.style.position = 'relative';
    }

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      navigate('/m-history');
    } else {
      navigate('/history');
    }
  }, [navigate]);

  // https://metamask.app.link/dapp/pefud.com/donation

  const handleClickQuiz = () => {
    window.open('https://forms.gle/NwD6Td88snCcPLNv9', '_blank');
  };

  const handleClickStartHistory = () => {
    const audio = audioRef.current;
    setIsElementVisible(false);
    setShowSection(true);
    const startHistory = startHistoryRef.current;

    if (startHistory) startHistory.style.display = 'none';

    if (audio) {
      audio.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const root = document.querySelector('#root') as HTMLElement;
    if (root) {
      root.style.overflowY = 'hidden';
    }

    // Cleanup function to reset the overflowY style
    return () => {
      if (root) {
        root.style.overflowY = '';
      }
    };
  }, []);

  useEffect(() => {
    const root = document.querySelector('#root') as HTMLElement;
    if (isElementVisible) {
      if (root) {
        root.style.overflowX = 'hidden';
      }
    } else {
      if (root) {
        root.style.overflowX = 'auto';
      }
    }

    // Cleanup function to reset overflow when component unmounts or visibility changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isElementVisible]);
  return (
    <>
      <div className={`z-100 w-100% h-100vh fixed top-0 left-0 bg-[#f2d142] flexRow z-300 ${hiddenHandle ? 'hidden' : ''}`}>
        <div className='w-100% h-100% relative flexRow'>
          <div className='w-[120rem] h-[50rem] flexRow pb-[3rem] p:hidden'>
            <h2 className='text-[6rem] l:text-[6rem] font-[800] text-black pr-[0.3rem]'>LOADING</h2>
            <ReactTyped strings={['...']} typeSpeed={350} backSpeed={0} backDelay={350} showCursor={false} loop={true} style={{ fontSize: '6rem', color: 'black', fontFamily: 'roboto', fontWeight: '800', paddingBottom: '0.5rem' }} />
          </div>
          <div className='hidden p:flexRow'>
            <h2 className='text-[3rem] font-[800] text-black pr-[0.3rem]'>LOADING</h2>
            <ReactTyped strings={['...']} typeSpeed={350} backSpeed={0} backDelay={350} showCursor={false} loop={true} style={{ fontSize: '3rem', color: 'black', fontFamily: 'roboto', fontWeight: '800', paddingBottom: '0.5rem' }} />
          </div>
          <div className='w-[20rem] h-[20rem] animate-[show-left-and-remove_5s_linear_infinite] top-[45%] absolute left-[-30rem]'>
            <div className='bg-contain bg-center bg-no-repeat w-[20rem] h-[20rem] animate-[bounce-wobble_0.4s_linear_infinite]' style={{ backgroundImage: `url("/images/${timerHandle}.png")` }}></div>
          </div>
          <div className='w-[20rem] h-[20rem] animate-[show-left-and-remove_5s_linear_infinite] top-[50%] absolute left-[-20rem]'>
            <div className='bg-contain bg-center bg-no-repeat w-[10rem] h-[10rem] animate-[wobble-ver-left_5s_linear_infinite]' style={{ backgroundImage: `${timerHandle === 73 ? 'url("/images/74.png")' : 'url("/images/bone.png")'}` }}></div>
          </div>
          <div className='absolute bottom-[-1rem] bg-center bg-no-repeat bg-contain w-[10rem] h-[10rem]' style={{ backgroundImage: 'url("/images/88.png")' }}></div>
        </div>
      </div>
      <div className='z-200 w-[100vw] h-100vh absolute top-[0] left-[0] bg-[black] flexCol cursor-pointer z-100' onClick={handleClickStartHistory} ref={startHistoryRef}>
        <div className='text-white text-[7rem] l:text-[5rem] p:text-[3.5rem] t:text-[4vw] font-[800] font-pixelify'>Click to start!</div>
        <div className='text-white text-[3.5rem] l:text-[3rem] p:text-[2rem] border border-white rounded-[0.3rem] font-[800] border-solid font-roboto py-[0.5rem] px-[2rem] mt-[5rem] p:mt-[3rem] cursor-pointer border-[0.2rem] font-pixelify'>click</div>
      </div>
      <audio ref={audioRef} src='/music/a.mp4' />
      <section className={`${orientation === 'landscape' ? 'w-[2500vw] h-[100vh] flexRow' : 'w-[2500vw] h-[100vh] flexRow'} overflow-scroll absolute ${hiddenHandle === true && showSection === true ? '' : 'hidden'}`} ref={sectionRef}>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>PeFUD was originally a brave and strong military dog who loved his comrades dearly and won battle. He had various combat experiences and saved many casualties.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story01.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story02.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>However, after experiencing numerous battles and witnessing many of his beloved comrades die in combat, he felt an immense sadness. This led him to want to help injured and retired military dogs who had endured hardships, rather than continue fighting.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story03.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story04.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>After retiring, PeFUD began to diligently help other injured and retired military dogs. This brought him a great sense of fulfillment and happiness.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story05.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story06.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>As time went by, however, PeFUD realized how inadequate the treatment and welfare of retired and injured military dogs were. He felt frustrated by his inability to make a significant difference due to a lack of funds.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story07.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story08.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>Feeling that retired and injured military dogs needed financial support, he decided to invest in crypto to earn a substantial amount of money. He invested in $ZEN, but the value of $ZEN kept falling while only meme coins were increasing in price, leaving him disheartened and frustrated.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story09.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>As a result, PeFUD started focusing heavily on investing in meme coins. However, the continuous investments, especially putting his retirement savings into $pepe coin, led to significant losses. Over time, these losses began to change his appearance, and eventually, he transformed to resemble Pepe.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story10.jpg")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story11.jpg")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story12.jpg")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>Our proud hero PeFUD eventually transformed from a hero into a meme coin monster.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story13.jpg")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story14.png")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center leading-[4vw]`}>And so, the corrupted PeFUD decided to immortalize his appearance as an NFT on the horizen EON chain block forever.</article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain relative`} style={{ backgroundImage: 'url("/images/story15.jpg")' }}></article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexRow text-white text-center`}>
          <div className='text-orange font-roboto text-[6vw] text-center pr-[1vw]'>NFT</div>
          <div className='text-white font-roboto text-[6vw] text-center'>coming soon!!</div>
        </article>
        <article className={`${orientation === 'landscape' ? 'h-[100vh]' : 'h-[100vh]'} w-[100vw] bg-center bg-no-repeat bg-contain font-[600] font-roboto text-[2.5vw] px-[7vw] flexCol text-white text-center`}>
          <div className='text-white font-roboto text-[6vw] text-center pb-[5vw] font-pixelify'>Go to quiz?</div>
          <div className='text-white font-roboto text-[3vw] text-center border border-white border-solid rounded-[0.5vw] px-[7vw] pt-[1vw] pb-[0.9vw] font-pixelify' onClick={handleClickQuiz}>
            click
          </div>
        </article>
      </section>
    </>
  );
};

export default History;
