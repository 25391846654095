import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../stores/store';
import { checkInstalledMetaMask, eonChainNetWorkInfo, requestAuthLogin, requestAuthMessage, requestBonePoints, requestPermissionAddresses } from '../services';
import { setBonesPoint, setChainId, setPermissionAddresses, setProvider, setWalletAddress } from '../stores/walletSlice';
import If from '../components/If';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  backgroundColor?: string;
  firstLogo?: string;
  secondLogo?: string;
  logoColor?: string;
};

const DonationHeader = ({ backgroundColor = '', firstLogo = '', secondLogo = '', logoColor = '' }: PropsType) => {
  const { chainId, walletAddress, permissionAddresses, bonesPoint } = useSelector((state: RootState) => state.wallet);
  const dispatch: AppDispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const [scriptString, setScriptString] = useState('');

  const [messageForTesting, setMessageForTesting] = useState('');
  const [accessTokenForTesting, setAccessTokenForTesting] = useState('');

  const [handleFirstForMobile, setHandleFirstForMobile] = useState(false);
  const [handleSecondForMobile, setHandleSecondForMobile] = useState(false);

  const navigate = useNavigate();

  const initState = () => {
    dispatch(setWalletAddress(''));
    dispatch(setPermissionAddresses([]));
    dispatch(setBonesPoint('0'));
    dispatch(setChainId(-1));
  };

  const init = async () => {
    if (window.ethereum) {
      const permissionAddresses = await window.ethereum.request({ method: 'eth_accounts' }).catch((err: any) => console.log(err));

      if (permissionAddresses.length === 0) {
        localStorage.clear();
        return;
      }

      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (permissionAddresses.length > 0 && (accessToken === null || refreshToken === null)) {
        localStorage.clear();
        return;
      }

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const walletAddress = (await signer.getAddress()).toLocaleLowerCase();
      const chainId = parseInt((await signer.provider.getNetwork()).chainId.toString());

      if (permissionAddresses.length > 0 && accessToken !== null && refreshToken !== null) {
        await requestBonePoints(walletAddress.toLocaleLowerCase(), accessToken, refreshToken)
          .then(async (res) => {
            const bonesPoint = res;

            dispatch(setPermissionAddresses(permissionAddresses));
            dispatch(setWalletAddress(walletAddress));
            dispatch(setChainId(chainId));
            dispatch(setBonesPoint(bonesPoint));
          })
          .catch(async (err) => {
            switch (err.response.data.message) {
              case 'Token is missing.':
                localStorage.clear();
                break;
              case 'Token error.':
                localStorage.clear();
                break;
              case 'Generate Token.':
                const accessToken = err.response.data.accessToken;
                const refreshToken = err.response.data.refreshToken;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                await requestBonePoints(walletAddress.toLocaleLowerCase(), accessToken, refreshToken)
                  .then((res) => {
                    const bonesPoint = res;

                    dispatch(setPermissionAddresses(permissionAddresses));
                    dispatch(setWalletAddress(walletAddress));
                    dispatch(setChainId(chainId));
                    dispatch(setBonesPoint(bonesPoint));
                  })
                  .catch((err) => {
                    localStorage.clear();
                    console.log(err);
                  });
                break;
              case 'Token has expired.':
                localStorage.clear();
                break;
              default:
                console.log('There is no error message.');
                localStorage.clear();
                break;
            }
          });
      }
    } else {
      console.log('You have to install metamask.');
    }
  };

  const handleClickConnectWallet = async () => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && checkInstalledMetaMask() === false) {
      const currentURL = window.location.href;

      if (currentURL.includes('pefud.com/donation')) window.open('https://metamask.app.link/dapp/pefud.com/donation');
      else if (currentURL.includes('pefud.com/mint')) window.open('https://metamask.app.link/dapp/pefud.com/mint');
      else if (currentURL.includes('pefud.com/reveal')) window.open('https://metamask.app.link/dapp/pefud.com/reveal');
    } else if (checkInstalledMetaMask() === true) {
      setScriptString('find error');

      try {
        await requestPermissionAddresses()
          .then(async (res) => {
            const { permissionAddresses, walletAddress, chainId, provider } = res;

            if (permissionAddresses.length === 0) return;

            try {
              requestAuthMessage(walletAddress)
                .then(async (res) => {
                  const { message, nonce } = res;
                  const provider = new ethers.BrowserProvider(window.ethereum);
                  const signer = await provider.getSigner();

                  setMessageForTesting(message);
                  provider
                    .send('personal_sign', [message, await signer.getAddress()])
                    .then(async (res) => {
                      const signature = res;

                      await requestAuthLogin(walletAddress, signature, nonce)
                        .then(async (res) => {
                          const { accessToken, refreshToken } = res;

                          setAccessTokenForTesting(accessToken);

                          await requestBonePoints(walletAddress.toLocaleLowerCase().toLocaleLowerCase(), accessToken, refreshToken)
                            .then(async (res) => {
                              const bonesPoint = res;

                              const chainId = parseInt((await signer.provider.getNetwork()).chainId.toString());

                              localStorage.setItem('accessToken', accessToken);
                              localStorage.setItem('refreshToken', refreshToken);

                              dispatch(setWalletAddress(walletAddress));
                              dispatch(setPermissionAddresses(permissionAddresses));
                              dispatch(setBonesPoint(bonesPoint));
                              dispatch(setChainId(chainId));
                            })
                            .catch(async (err) => {
                              console.log(err.toString());
                              if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                              else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                            });
                        })
                        .catch(async (err) => {
                          console.log('requestAuthLoginError:', err);
                          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                          else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                        });
                    })
                    .catch(async (err) => {
                      console.log('signMessageError:', err);
                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                    });
                })
                .catch(async (err) => {
                  console.log('requestAuthMessageError:', err);
                  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                  else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                });
            } catch (err) {
              setScriptString('error: requestAuthMessage');
              if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
              else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
            }
          })
          .catch(async (err) => {
            console.log(err.toString());
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
            else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
          });
      } catch (err) {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
        else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
      }
    } else {
      setScriptString('there is no metamask.');
      window.open('https://metamask.io/download/', '_blank');
    }
  };

  const handleClickDisconnectWallet = async () => {
    console.log('clickedHandleClickDisconnect');
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
    else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
  };

  const handleClickConnectEonNetwork = async () => {
    await window.ethereum.request({ method: 'wallet_addEthereumChain', params: [eonChainNetWorkInfo] }).catch((err: any) => {
      console.log(err);
    });
  };

  const handleAccountsChanged = async (accounts: string[]) => {
    const newAddress = accounts[0];

    if (accounts.length === 0) {
      dispatch(setWalletAddress(''));
      dispatch(setPermissionAddresses([]));
      dispatch(setBonesPoint('0'));
      dispatch(setChainId(-1));
      localStorage.clear();
    } else if (accounts.length === 1) {
    } else {
      requestAuthMessage(newAddress)
        .then(async (res) => {
          const { message, nonce } = res;
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          await signer
            .signMessage(message)
            .then(async (res) => {
              const signature = res;

              await requestAuthLogin(newAddress.toLocaleLowerCase(), signature, nonce)
                .then(async (res) => {
                  const { accessToken, refreshToken } = res;

                  await requestBonePoints(newAddress.toLocaleLowerCase(), accessToken, refreshToken)
                    .then(async (res) => {
                      const bonesPoint = res;

                      const chainId = parseInt((await signer.provider.getNetwork()).chainId.toString());

                      localStorage.setItem('accessToken', accessToken);
                      localStorage.setItem('refreshToken', refreshToken);

                      dispatch(setWalletAddress(newAddress));
                      dispatch(setPermissionAddresses(accounts));
                      dispatch(setBonesPoint(bonesPoint));
                      dispatch(setChainId(chainId));
                    })
                    .catch(async (err) => {
                      console.log('requestBonePointsError:', err);

                      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                      else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                    });
                })
                .catch(async (err) => {
                  console.log('requestAuthLoginError:', err);

                  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
                  else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
                });
            })
            .catch(async (err) => {
              console.log('signMessageError:', err);
              if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) initState();
              else await window.ethereum.request({ method: 'wallet_revokePermissions', params: [{ eth_accounts: {} }] }).catch((err: any) => console.log(err.toString()));
            });
        })
        .catch((err) => {
          console.log('requestAuthMessageError:', err);
        });
    }
  };

  const handleChainChanged = (chainId: string) => {
    dispatch(setChainId(parseInt(chainId, 16)));
  };

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (checkInstalledMetaMask() === true) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        if (window.ethereum) {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', handleChainChanged);
        }
      };
    }
  }, [dispatch]);

  const handleClickLogo = () => {
    navigate('/');
  };

  return (
    <>
      <header className={`w-100% flexRow px-[4rem] l:px-[3rem] p:px-[2rem] t:px-[2vw] backdrop-blur-sm fixed transition-all-300 z-200 ${scrolled ? 'py-[0.2rem] l:py-[0.2rem] p:py-[0.2rem] t:py-[0.5vw] ' : 'py-[1.5rem] l:py-[1rem] p:py-[0.3rem] t:py-[1.4vw] d:bg-transparent '}`} style={{ backgroundColor }}>
        <nav className={`max-w-[120rem] w-100% rounded-[0.5rem] py-[0.8rem] px-[2rem] flexRow justify-between transition-all-300 bg-transparent t:rounded-[0.2vw] t:py-[0.3vw] t:px-[2vw]`}>
          <div className='flexRow items-end cursor-pointer' onClick={handleClickLogo}>
            <div className={`text-[3rem] leading-[3rem] pb-[0.5rem] text-red font-oswald font-[800] [text-shadow:0px_4px_4px_#000000] [-webkit-text-stroke:1.5px_#000000] mr-[0.3rem] p:text-[2.5rem] p:[text-shadow:0px_2px_2px_#000000] p:[-webkit-text-stroke:1px_#000000] t:text-[5vw] t:leading-[5vw] t:pb-[0.2vw] t:[text-shadow:0px_0.5vw_0.5vw_#000] t:[-webkit-text-stroke:1px_#000] ${logoColor === '' ? 'text-red' : ''}`} style={{ color: logoColor }}>
              {firstLogo === '' ? 'DONATE' : firstLogo}
            </div>
            <div className='text-[1.5rem] leading-[1.8rem] pl-[0.2rem] font-impact font-[800] pb-[0.1rem] t:text-[2.5vw] t:leading-[2.5vw] t:pl-[0.2vw] t:pb-[0rem]'>{secondLogo === '' ? 'To PeFUD' : secondLogo}</div>
          </div>
          <div className='flexRow'>
            <If condition={walletAddress === ''}>
              <button className='h-[4.5rem] p:h-[3.5rem] p:text-[1.7rem] text-[2rem] leading-[2rem] pt-[0rem] font-impact font-[800] border-[0.24rem] l:border-[0.21rem] p:border-[0.15rem] t:border-[0.1rem] rounded-[0.5rem] px-[2rem] cursor-pointer bg-custom-white t:h-[5.5vw] t:text-[2.5vw] t:leading-[2.5vw] t:px-[2vw] t:rounded-[0.5vw]' onClick={handleClickConnectWallet}>
                Connect Wallet
              </button>
            </If>
            <If condition={walletAddress !== '' && chainId !== parseInt(eonChainNetWorkInfo.chainId, 16)}>
              <button className='h-[4.5rem] p:h-[3.5rem] p:text-[1.7rem] border-[0.24rem] l:border-[0.21rem] p:border-[0.15rem] t:border-[0.1rem] rounded-[0.5rem] px-[2rem] flexRow overflow-hidden cursor-pointer bg-custom-white t:h-[5.5vw] t:text-[2.5vw] t:leading-[2.5vw] t:px-[2vw] t:rounded-[0.5vw]' onClick={handleClickConnectEonNetwork}>
                <div className='rounded-50% mb-[0.1rem] w-[2rem] h-[2rem] mr-[1.2rem] bg-red mt-[0.1rem] animate-[light_1s_ease-out_infinite] p:w-[1.6rem] p:h-[1.6rem] t:w-[2.5vw] t:h-[2.5vw] t:mr-[1.5vw]'></div>
                <div className='text-[2rem] leading-[2rem] pt-[0rem] p:text-[1.7rem] font-impact font-[800] t:text-[2.5vw] t:leading-[2.5vw]'>Switch EON Network</div>
              </button>
            </If>
            <If condition={walletAddress !== '' && chainId === parseInt(eonChainNetWorkInfo.chainId, 16)}>
              <div className='h-[4.5rem] p:h-[3.5rem] p:text-[1.7rem] border-[0.24rem] l:border-[0.21rem] p:border-[0.15rem] t:border-[0.1rem] rounded-[0.5rem] flexRow overflow-hidden pr-[2rem] pl-[1rem] bg-custom-white mr-[1rem] t:h-[5.5vw] t:text-[2.5vw] t:leading-[2.5vw] t:px-[2vw] t:rounded-[0.5vw] t:mr-[1vw] t:pl-[0.7vw] t:pr-[2vw]'>
                <div className=' w-[4rem] h-[4rem] bg-center bg-no-repeat bg-contain mr-[0.6rem] mt-[0rem] t:w-[5.5vw] t:h-[5.5vw] t:mr-[0.5vw]' style={{ backgroundImage: 'url("/images/bone.png")' }}></div>
                <div className='text-[2rem] leading-[2rem] p:text-[1.7rem] p:leading-[1.7rem] pt-[0rem] mr-[0.5rem] font-impact font-[800] t:text-[2.5vw] t:leading-[2.5vw]'>BONES:</div>
                <div className='text-[2rem] leading-[2rem] p:text-[1.7rem] p:leading-[1.7rem] pt-[0rem] font-impact font-[800] t:text-[2.5vw] t:leading-[2.5vw]'>{parseFloat((+bonesPoint).toFixed(2))}</div>
              </div>
              <button className='h-[4.5rem] p:h-[3.5rem] p:text-[1.7rem] text-[2rem] leading-[2rem] pt-[0rem] font-impact font-[800] border-[0.24rem] l:border-[0.21rem] p:border-[0.15rem] t:border-[0.1rem] rounded-[0.5rem] px-[2rem] cursor-pointer bg-custom-white t:h-[5.5vw] t:text-[2.5vw] t:leading-[2.5vw] t:px-[2vw] t:rounded-[0.5vw]' onClick={handleClickDisconnectWallet}>
                Disconnect Wallet
              </button>
            </If>
          </div>
        </nav>
      </header>
      {/* <div className='fixed top-[10vw] w-[100%] h-[3vw] bg-coral z-[300] font-roboto'>console1: {scriptString}</div>
      <div className='fixed top-[13vw] w-[100%] h-[3vw] bg-coral z-[300] font-roboto'>
        localStorage.accessToken: {localStorage.getItem('accessToken')}, accessToken: {accessTokenForTesting}
      </div>
      <div className='fixed top-[16vw] w-[100%] h-[3vw] bg-coral z-[300] font-roboto'>message: {messageForTesting}</div> */}
    </>
  );
};

export default DonationHeader;
