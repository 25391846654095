import { Provider } from 'react-redux';
import store from './stores/store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import { useEffect } from 'react';
import Game from './pages/Game';
import History from './pages/History';
import Donation from './pages/Donation';
import Mint from './pages/Mint';
import Example from './pages/Example';
import MobileHistory from './pages/MobileHistory';
import HyperDX from '@hyperdx/browser';
import Reveal from './pages/Reveal';
import Staking from './pages/Staking';

const App = () => {
  useEffect(() => {
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');

    if (prefersDarkScheme.matches) {
      document.documentElement.style.colorScheme = 'light';
    }
  }, []);

  HyperDX.init({
    apiKey: process.env.REACT_APP_HYPERDX_API_KEY || '',
    service: 'my-frontend-app',
    tracePropagationTargets: [/api.myapp.domain/i],
    consoleCapture: true,
    advancedNetworkCapture: true
  });

  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path='/' element={<Main />} />
            <Route path='/donation' element={<Donation />} />
            <Route path='/history' element={<History />} />
            <Route path='/m-history' element={<MobileHistory />} />
            <Route path='/mint' element={<Mint />} />
            {/* <Route path='/staking' element={<Staking />} />
            <Route path='/game' element={<Game />} /> */}
            {/* <Route path='/reveal' element={<Reveal />} /> */}
            {/* <Route path='/example' element={<Example />} /> */}
          </Routes>
        </Router>
      </Provider>
    </>
  );
};

export default App;
