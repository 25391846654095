import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: ReactNode;
  handleModal: boolean;
  setHandleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setHandlerClickGacha?: React.Dispatch<React.SetStateAction<string>>;
};

const Portal = ({ children, handleModal, setHandleModal, setHandlerClickGacha = () => {} }: PortalProps) => {
  const portalRoot = document.querySelector('#portal');

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setHandleModal(false);
      }
    };

    const disableScroll = () => (document.body.style.overflow = 'hidden');

    const enableScroll = () => (document.body.style.overflow = '');

    disableScroll();

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);

      enableScroll();
    };
  }, [handleModal]);

  const handleLClickCloseModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (event.target === event.currentTarget) {
      setHandleModal(false);
      setHandlerClickGacha('ready');
    }
  };

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <>
      <section className='fixed w-[100%] h-[100%] top-0 left-0 flexCol backdrop-blur-sm cursor-pointer' style={{ zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.7)' }} onClick={handleLClickCloseModal}>
        {children}
      </section>
    </>,
    portalRoot
  );
};

export default Portal;
